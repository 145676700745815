import { provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AppRoutingModule } from "@spartacus/storefront";
import { AppComponent } from './app.component';
import { SpartacusModule } from "./spartacus/spartacus.module";
import { ConfigModule, RoutingConfig } from "@spartacus/core";
import { customLayoutConfig } from "./SGRE-custom/custom-layout-config";
import { SGRECustomModules } from "./SGRE-custom/SGRE-custom.module";
import { SGRESharedModule } from "./SGRE-shared/SGRE-shared.module";
import { PrimengModule } from "./SGRE-custom/primeng/primeng.module";
import { AngularMaterialModule } from "./SGRE-custom/angular-material/angular-material.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from "@binssoft/ngx-captcha";

import { customGlobalMessageConfig } from "./SGRE-config/message-config";

// Import MSAL and MSAL browser libraries
import { MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

// Import the Azure AD configuration
import { msalConfig } from './SGRE-config/auth-config';

// Import the Azure AD related Services
import { AuthHttpHeaderService, AuthService } from "@spartacus/core";
import { MSALAuthHttpHeaderService } from "./SGRE-shared/services/login-sso/msal-auth-http-header.service";
import { MSALSpaAuthService } from "./SGRE-shared/services/login-sso/msal-auth.service";
import { translationChunksConfig, translations } from "@spartacus/assets";
import { environment } from "../environments/environment";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    EffectsModule.forRoot([]),
    SpartacusModule,
    SGRECustomModules,
    MsalModule.forRoot(
      MSALSpaAuthService.msalInstance,
      null,
      null
    ),
    ConfigModule.withConfig({
      layoutSlots: customLayoutConfig.layoutSlots,
    }),
    ConfigModule.withConfig({
      routing: {
        routes: {
          login: {
            paths: ['homepage'],
          },
          product: {
            paths: ['shop/product-details']
            // shop/product-details?partNumber=A9B00562167
          }
        },
      },
    } as RoutingConfig),
    ConfigModule.withConfig(customGlobalMessageConfig),
    SGRESharedModule.forRoot(),
    PrimengModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
  ],
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideAnimationsAsync(),
    {
      provide: AuthHttpHeaderService,
      useExisting: MSALAuthHttpHeaderService,
    },
    {
      provide: AuthService,
      useExisting: MSALSpaAuthService,
    },
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule { }
