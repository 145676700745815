import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { ConfigModule, HttpErrorHandler, I18nModule } from '@spartacus/core';
import { PrimengModule } from '../SGRE-custom/primeng/primeng.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpBackend } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { CustomGlobalInterceptor } from './services/interceptor/custom.interceptor';
import { CustomErrorHandler } from './services/error-handler/custom-error-handler.service';

import { FilterDropdownPipe } from './pipes/filter-dropdown.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { CurrencyPricePipe } from './pipes/currency-price.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';

import { CardTemplateComponent } from './components/card-template/card-template.component';
import { TableTemplateComponent } from './components/table-template/table-template.component';
import { PaginationTemplateComponent } from './components/pagination-template/pagination-template.component';
import { NumberInputTemplateComponent } from './components/number-input-template/number-input-template.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

import { SharedLinkComponent } from './components/shared-link/shared-link.component';
import { SharedParagraphComponent } from './components/shared-paragraph/shared-paragraph.component';
import { SharedNavigationComponent } from './components/shared-navigation/shared-navigation.component';
import { SharedImageComponent } from './components/shared-image/shared-image.component';
import { SharedReferenceComponent } from './components/shared-reference/shared-reference.component';

import { FooterSocialmediaIconsComponent } from './components/TypeCode Components/footer-socialmedia-icons/footer-socialmedia-icons.component';
import { HeaderSocialmediaIconsComponent } from './components/TypeCode Components/header-socialmedia-icons/header-socialmedia-icons.component';
import { HeaderContactusComponent } from './components/TypeCode Components/header-contactus/header-contactus.component';
import { HeaderFaqComponent } from './components/TypeCode Components/header-faq/header-faq.component';
import { FooterAddressInfoComponent } from './components/TypeCode Components/footer-address-info/footer-address-info.component';
import { FooterNoticeComponent } from './components/TypeCode Components/footer-notice/footer-notice.component';
import { ContactusParagraphComponent } from './components/TypeCode Components/contactus-paragraph/contactus-paragraph.component';
import { FooterContactUsComponent } from './components/TypeCode Components/footer-contact-us/footer-contact-us.component';
import { FaqNavigationComponent } from './components/TypeCode Components/faq-navigation/faq-navigation.component';
import { HeaderSignInComponent } from './components/TypeCode Components/header-sign-in/header-sign-in.component';
import { FooterTermsOfUseComponent } from './components/TypeCode Components/footer-terms-of-use/footer-terms-of-use.component';
import { TermsOfUseSocialMediaComponent } from './components/TypeCode Components/terms-of-use-social-media/terms-of-use-social-media.component';
import { BulkorderDownloadParagraphComponent } from './components/TypeCode Components/bulkorder-download-paragraph/bulkorder-download-paragraph.component';
import { BulkorderUploadParagraphComponent } from './components/TypeCode Components/bulkorder-upload-paragraph/bulkorder-upload-paragraph.component';
import { BulkorderUploadTypeParagraphComponent } from './components/TypeCode Components/bulkorder-upload-type-paragraph/bulkorder-upload-type-paragraph.component';
import { BulkorderProductFoundParagraphComponent } from './components/TypeCode Components/bulkorder-product-found-paragraph/bulkorder-product-found-paragraph.component';
import { BulkorderProductNotfoundParagraphComponent } from './components/TypeCode Components/bulkorder-product-notfound-paragraph/bulkorder-product-notfound-paragraph.component';
import { FooterImageComponent } from './components/TypeCode Components/footer-image/footer-image.component';
import { HomepageImageSection5Component } from './components/TypeCode Components/homepage-image-section5/homepage-image-section5.component';
import { HomepageImageSection6Component } from './components/TypeCode Components/homepage-image-section6/homepage-image-section6.component';
import { HomepageImageSection7Component } from './components/TypeCode Components/homepage-image-section7/homepage-image-section7.component';
import { HomepageImageSection8Component } from './components/TypeCode Components/homepage-image-section8/homepage-image-section8.component';
import { HomepageParagraphSection4Component } from './components/TypeCode Components/homepage-paragraph-section4/homepage-paragraph-section4.component';
import { HomepageParagraphSection5Component } from './components/TypeCode Components/homepage-paragraph-section5/homepage-paragraph-section5.component';
import { HomepageParagraphSection6Component } from './components/TypeCode Components/homepage-paragraph-section6/homepage-paragraph-section6.component';
import { HomepageParagraphSection8Component } from './components/TypeCode Components/homepage-paragraph-section8/homepage-paragraph-section8.component';
import { HomepageParagraphSection9Component } from './components/TypeCode Components/homepage-paragraph-section9/homepage-paragraph-section9.component';
import { PdpAlteranteProductComponent } from './components/TypeCode Components/pdp-alterante-product/pdp-alterante-product.component';
import { PdpRelatedProductComponent } from './components/TypeCode Components/pdp-related-product/pdp-related-product.component';
import { NoProductsFoundComponent } from './components/TypeCode Components/no-products-found/no-products-found.component';
import { HomepageImageSection9Component } from './components/TypeCode Components/homepage-image-section9/homepage-image-section9.component';
import { MyProfileParagraphComponent } from './components/TypeCode Components/my-profile-paragraph/my-profile-paragraph.component';
import { ProfileParagraphComponent } from './components/TypeCode Components/profile-paragraph/profile-paragraph.component';
import { PurchaseTermsAndConditionComponent } from './components/TypeCode Components/purchase-terms-and-condition/purchase-terms-and-condition.component';
import { ProfileExternalParagraphComponent } from './components/TypeCode Components/profile-external-paragraph/profile-external-paragraph.component';
import { CookiePolicyParagraphComponent } from './components/TypeCode Components/cookie-policy-paragraph/cookie-policy-paragraph.component';
import { CookiePolicySocialComponent } from './components/TypeCode Components/cookie-policy-social/cookie-policy-social.component';
import { PrivacyPolicySocialMediaComponent } from './components/TypeCode Components/privacy-policy-social-media/privacy-policy-social-media.component';
import { PrivacyPolicySection3ButtonsComponent } from './components/TypeCode Components/privacy-policy-section3-buttons/privacy-policy-section3-buttons.component';
import { PageNotFoundLinkComponent } from './components/TypeCode Components/page-not-found-link/page-not-found-link.component';
import { PageNotFoundParagraphComponent } from './components/TypeCode Components/page-not-found-paragraph/page-not-found-paragraph.component';
import { SocialMediaLinkHeaderFacebookComponent } from './components/TypeCode Components/social-media-link-header-facebook/social-media-link-header-facebook.component';
import { SocialMediaLinkHeaderLinkedinComponent } from './components/TypeCode Components/social-media-link-header-linkedin/social-media-link-header-linkedin.component';
import { SocialMediaLinkHeaderInstagramComponent } from './components/TypeCode Components/social-media-link-header-instagram/social-media-link-header-instagram.component';
import { FooterSocialMediaFacebookComponent } from './components/TypeCode Components/footer-social-media-facebook/footer-social-media-facebook.component';
import { FooterSocialMediaLinkedinComponent } from './components/TypeCode Components/footer-social-media-linkedin/footer-social-media-linkedin.component';
import { FooterSocialMediaInstagramComponent } from './components/TypeCode Components/footer-social-media-instagram/footer-social-media-instagram.component';
import { FooterFollowUsTextComponent } from './components/TypeCode Components/footer-follow-us-text/footer-follow-us-text.component';
import { NoRequestFoundComponent } from './components/TypeCode Components/no-request-found/no-request-found.component';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { SharedVideoComponent } from './components/shared-video/shared-video.component';
import { FaqVideoPopupComponent } from './components/TypeCode Components/faq-video-popup/faq-video-popup.component';
import { HomepageVideoSection4Component } from './components/TypeCode Components/homepage-video-section4/homepage-video-section4.component';

export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, [
    '/assets/i18n/core/',
    '/assets/i18n/vendors/',
  ]);
}

@NgModule({
  declarations: [
    FilterDropdownPipe,
    FormatDatePipe,
    CurrencyPricePipe,
    CurrencyFormatPipe,
    CardTemplateComponent,
    TableTemplateComponent,
    PaginationTemplateComponent,
    NumberInputTemplateComponent,
    SpinnerComponent,
    SharedLinkComponent,
    SharedParagraphComponent,
    SharedNavigationComponent,
    SharedImageComponent,
    SharedReferenceComponent,
    FooterSocialmediaIconsComponent,
    HeaderSocialmediaIconsComponent,
    HeaderContactusComponent,
    HeaderFaqComponent,
    FooterAddressInfoComponent,
    FooterNoticeComponent,
    ContactusParagraphComponent,
    FooterContactUsComponent,
    FaqNavigationComponent,
    HeaderSignInComponent,
    FooterTermsOfUseComponent,
    TermsOfUseSocialMediaComponent,
    BulkorderDownloadParagraphComponent,
    BulkorderUploadParagraphComponent,
    BulkorderUploadTypeParagraphComponent,
    BulkorderProductFoundParagraphComponent,
    BulkorderProductNotfoundParagraphComponent,
    FooterImageComponent,
    HomepageImageSection5Component,
    HomepageImageSection6Component,
    HomepageImageSection7Component,
    HomepageImageSection8Component,
    HomepageParagraphSection4Component,
    HomepageParagraphSection5Component,
    HomepageParagraphSection6Component,
    HomepageParagraphSection8Component,
    HomepageParagraphSection9Component,
    PdpAlteranteProductComponent,
    PdpRelatedProductComponent,
    NoProductsFoundComponent,
    HomepageImageSection9Component,
    MyProfileParagraphComponent,
    ProfileParagraphComponent,
    PurchaseTermsAndConditionComponent,
    ProfileExternalParagraphComponent,
    CookiePolicyParagraphComponent,
    CookiePolicySocialComponent,
    PrivacyPolicySocialMediaComponent,
    PrivacyPolicySection3ButtonsComponent,
    PageNotFoundLinkComponent,
    PageNotFoundParagraphComponent,
    SocialMediaLinkHeaderFacebookComponent,
    SocialMediaLinkHeaderLinkedinComponent,
    SocialMediaLinkHeaderInstagramComponent,
    FooterSocialMediaFacebookComponent,
    FooterSocialMediaLinkedinComponent,
    FooterSocialMediaInstagramComponent,
    FooterFollowUsTextComponent,
    NoRequestFoundComponent,
    CaptchaComponent,
    SharedVideoComponent,
    FaqVideoPopupComponent,
    HomepageVideoSection4Component
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    ConfigModule,
    PrimengModule,
    FormsModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSLinkComponent: {
          component: SharedLinkComponent,
        },
        CMSParagraphComponent: {
          component: SharedParagraphComponent,
        },
        NavigationComponent: {
          component: SharedNavigationComponent,
        },
        CMSImageComponent: {
          component: SharedImageComponent,
        },
        ProductReferencesComponent: {
          component: () => import('./components/shared-reference/shared-reference.component').then(m => m.SharedReferenceComponent),
        },
        VideoComponent: {
          component: SharedVideoComponent
        },
      },
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend],
      },
    }),
    I18nModule,
  ],
  exports: [
    FilterDropdownPipe,
    FormatDatePipe,
    CurrencyPricePipe,
    CurrencyFormatPipe,
    CardTemplateComponent,
    TableTemplateComponent,
    PaginationTemplateComponent,
    NumberInputTemplateComponent,
    SpinnerComponent,
    SharedLinkComponent,
    SharedParagraphComponent,
    SharedNavigationComponent,
    SharedImageComponent,
    SharedReferenceComponent,
    CaptchaComponent,
  ],
})
export class SGRESharedModule {
  static forRoot(): ModuleWithProviders<SGRESharedModule> {
    return {
      ngModule: SGRESharedModule,
      providers: [
        DatePipe,
        TranslateService,
        { provide: TranslateStore },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CustomGlobalInterceptor,
          multi: true,
        },
        {
          provide: HttpErrorHandler,
          useClass: CustomErrorHandler,
          multi: true,
        },
      ],
    };
  }
}
