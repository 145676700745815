import { ErrorHandler, Inject, Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {

    constructor(
        private zone: NgZone
    ) { }

    handleError(error: unknown): void {
        this.zone.run(() => {
            if (error instanceof Error) {
                console.warn('Error constructor type - captured');
            }
            if (error instanceof HttpErrorResponse) {
                console.warn('HttpErrorResponse - captured');
            }
            if (error instanceof HttpRequest) {
                console.warn('HttpRequest - captured');
            }
            console.warn('Caught by custom error handler:', error);
        });
    }
}
