import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderRequestsComponent } from './Components/order-requests/order-requests.component';
import { ConfigModule } from '@spartacus/core';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';
import { PrimengModule } from '../primeng/primeng.module';

@NgModule({
  declarations: [
    OrderRequestsComponent
  ],
  imports: [
    CommonModule,
    SGRESharedModule,
    PrimengModule,
    ConfigModule.withConfig({
      cmsComponents: {
        OrderRequestsTableComponent: {
          component: () => import('./Components/order-requests/order-requests.component').then(m => m.OrderRequestsComponent),
          guards: [CustomRouteGuard]
        }
      }
    }),
    SGRESharedModule
  ]
})
export class OrderRequestsModule { }
