import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@spartacus/core';
import { Observable, tap } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { StorageService } from '../storage.service';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root',
})
export class CustomRouteGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private msalService: MsalService,
    private storageService: StorageService,
    private globalService: GlobalService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean | UrlTree {
    return this.authService.isUserLoggedIn().pipe(
      tap((isLoggedIn) => {
        if (!isLoggedIn) {
          this.storageService.redirectUrl = state.url;
          this.globalService.checkSmartEdit()
            ? this.msalService.loginPopup()
            : this.msalService.loginRedirect();
        }
      })
    );
  }
}