import { Component, ElementRef, ViewChild } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-faq-order-video-popup',
  templateUrl: './faq-order-video-popup.component.html',
  styleUrl: './faq-order-video-popup.component.scss'
})
export class FaqOrderVideoPopupComponent {

  @ViewChild('videoPlayer') el: ElementRef;
  public componentData: any;
  videoUrl: string;

  constructor(
    public component: CmsComponentData<any>,
    private elRef:ElementRef,
  ) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
      this.videoUrl = environment.siteUrls.getBackofficeUrl + data?.video?.url.slice('/medias');
    });
  }

  stopVideo(video) {
    video.pause();
  }
}
