import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { customLayoutConfig } from './custom-layout-config';
import { PageSlotModule } from '@spartacus/storefront';

import { HeaderModule } from './header/header.module';
import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { ContactUsModule } from './contactUs/contactus.module';
import { ProductListPageModule } from './product-list-page/product-list-page.module';
import { ProductDetailsPageModule } from './product-details-page/product-details-page.module';
import { FooterModule } from './footer/footer.module';
import { MyProfileModule } from './my-profile/my-profile.module';
import { InviteUserModule } from './invite-user/invite-user.module';
import { BulkOrderPageModule } from './bulk-order-page/bulk-order-page-module';
import { SaveCartModule } from './save-cart/save-cart.module';
import { CartModule } from './cart/cart.module';
import { UsersModule } from './Users/Users.module';
import { CheckoutPageModule } from './checkout/checkout.module';
import { FaqModule } from './faq/faq.module';
import { OrderDetailModule } from './order-details/orderDetails.module';
import { OrderHistoryModule } from './order-history/order-history.module';

import { RequestAccessModule } from '../SGRE-custom/request-access/request-access.module';
import { USerProfileModule } from './user-profile/user-profile.module';
import { AccessRequestModule } from '../SGRE-custom/access-requests/access-request.module';

import { OrderRequestsModule } from './order-requests/order-requests.module';
import { PrivacyPolicyContainer1Component } from './privacy-policy/Components/privacy-policy-container1/privacy-policy-container1.component';
import { PrivacyPolicyModule } from './privacy-policy/privacy-policy/privacy-policy.module';
import { PrivacyPolicyButtonsSection3Component } from './privacy-policy/Components/privacy-policy-buttons-section3/privacy-policy-buttons-section3.component';
import { CookiePolicyModule } from './cookie-policy/cookie-policy/cookie-policy.module';
import { HomepageModule } from './homepage/homepage.module';
@NgModule({
  declarations: [

    // PrivacyPolicyContainer1Component,
    // PrivacyPolicyButtonsSection3Component
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    HomepageModule,
    HeaderModule,
    BreadcrumbModule,
    ContactUsModule,
    ProductListPageModule,
    ProductDetailsPageModule,
    PrivacyPolicyModule,
    CookiePolicyModule,
    FooterModule,
    FaqModule,
    MyProfileModule,
    InviteUserModule,
    BulkOrderPageModule,
    SaveCartModule,
    CartModule,
    UsersModule,
    RequestAccessModule,
    OrderHistoryModule,
    OrderDetailModule,
    CheckoutPageModule,
    USerProfileModule,
    AccessRequestModule,
    OrderRequestsModule,
    ConfigModule.withConfig({
      layoutSlots: customLayoutConfig.layoutSlots,
    }),
  ]
})
export class SGRECustomModules { }
