<div class="container-fluid">
  <div class="row header-padding m-0 justify-content-between">
    <div class="custom-align-center">
      <cx-page-slot position="SiteLogo"></cx-page-slot>
    </div>
    <div class="header-contents-div pl-0 justify-content-center">
      <div class="custom-align-center justify-content-end">
        <ul class="custom-align-center mb-0 pl-0">
          <div>
            <cx-page-slot position="LanguageSelector"></cx-page-slot>
          </div>
          <div>
            <cx-page-slot position="ContactUs"></cx-page-slot>
          </div>
          <div>
            <cx-page-slot position="HeaderLinks"></cx-page-slot>
          </div>
          <div>
            <cx-page-slot position="LegalEntitySelector"></cx-page-slot>
          </div>
          <div>
            <cx-page-slot position="SignIn"></cx-page-slot>
          </div>
          <div class="custom-align-center">
            <cx-page-slot position="SocialMediaLinkHeader"></cx-page-slot>
          </div>
        </ul>
      </div>
      <div class="d-flex justify-content-end">
        <cx-page-slot position="NavigationBar"></cx-page-slot>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <cx-page-slot position="SearchBox"></cx-page-slot>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <cx-page-slot *ngIf="showBanner$ | async" position="AnonymousSignInBanner"></cx-page-slot>
    </div>
  </div>
</div>