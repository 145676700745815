import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { AuthGuard, ConfigModule, I18nModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';

import { HomepageCarouselSection1Component } from './components/homepage-carousel-section1/homepage-carousel-section1.component';
import { HomepageTopProductsSection2Component } from './components/homepage-top-products-section2/homepage-top-products-section2.component';
import { HomepageCategorySection3Component } from './components/homepage-category-section3/homepage-category-section3.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}
@NgModule({
  declarations: [
    HomepageCarouselSection1Component,
    HomepageTopProductsSection2Component,
    HomepageCategorySection3Component,
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    PrimengModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        RotatingImagesComponent: {
          component: HomepageCarouselSection1Component,
        },
        TopFeaturedProductsFlexComponent: {
          component: HomepageTopProductsSection2Component,
        },
        HomepageCategoryComponent: {
          component: HomepageCategorySection3Component,
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend]
      }
  }),
    I18nModule
  ],
  providers:[ 
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class HomepageModule { }
