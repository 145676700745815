import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'trust'
})
export class SafeHtmlPipe implements PipeTransform {

  htmlContent: any;
  scriptContent: any;

  constructor(protected sanitizer: DomSanitizer) { }

  public transform(
    value: any,
    type: string
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        // console.log('html content -> ', this.sanitizer.bypassSecurityTrustHtml(value));
        // console.log('style content', this.sanitizer.bypassSecurityTrustStyle(value));
        // console.log('script content', this.sanitizer.bypassSecurityTrustScript(value));
        // console.log('url content', this.sanitizer.bypassSecurityTrustUrl(value));
        // console.log('resource url content', this.sanitizer.bypassSecurityTrustResourceUrl(value));
        // return this.sanitizer.bypassSecurityTrustHtml(value)
        return this.mergeHtmlWithScript(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value)
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value)
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value)
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value)
      default:
        throw new Error(`Invalid safe type specified: ${type}`)
    }
  }

  mergeHtmlWithScript(value) {
    this.scriptContent = this.sanitizer.bypassSecurityTrustScript(value);

    const script = this.scriptContent['changingThisBreaksApplicationSecurity'];
    // console.log('script content -> ', script);
    // console.log('type of script content -> ', typeof (script));

    this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(script);

    return this.htmlContent;

    // this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(value);
    // const html = this.htmlContent['changingThisBreaksApplicationSecurity'];
    // console.log('html content -> ', html);
    // console.log('type of html content -> ', typeof(html));

    // console.log('after passing to script -> ', this.sanitizer.bypassSecurityTrustScript(this.htmlContent[0]));
    // return this.sanitizer.bypassSecurityTrustScript(html);
  }

}

