export const ERROR_STATUS_CODES = {
    ERROR: [
        {
            ERROR_CODE: 501,
            ERROR_ID: "NOT_IMPLEMENTED"
        },
        {
            ERROR_CODE: 503,
            ERROR_ID: "SERVICE_UNAVAILABLE"
        },
        {
            ERROR_CODE: 505,
            ERROR_ID: "HTTP_VERSION_NOT_SUPPORTED"
        },
        {
            ERROR_CODE: 506,
            ERROR_ID: "VARIANT_ALSO_NEGOTIATES"
        },
        {
            ERROR_CODE: 507,
            ERROR_ID: "INSUFFICIENT_STORAGE"
        },
        {
            ERROR_CODE: 508,
            ERROR_ID: "LOOP_DETECTED"
        },
        {
            ERROR_CODE: 510,
            ERROR_ID: "NOT_EXTENDED"
        },
        {
            ERROR_CODE: 511,
            ERROR_ID: "NETWORK_AUTHENTICATION"
        }
    ],
    WARNING: [
        {
            ERROR_CODE: 400,
            ERROR_ID: "PAYMENT_REQUIRED"
        },
        {
            ERROR_CODE: 402,
            ERROR_ID: "PAYMENT_REQUIRED"
        },
        {
            ERROR_CODE: 405,
            ERROR_ID: "METHOD_NOT_ALLOWED"
        },
        {
            ERROR_CODE: 406,
            ERROR_ID: "NOT_ACCEPTABLE"
        },
        {
            ERROR_CODE: 407,
            ERROR_ID: "PROXY_AUTHENTICATION_REQUIRED"
        },
        {
            ERROR_CODE: 408,
            ERROR_ID: "REQUEST_TIMEOUT"
        },
        {
            ERROR_CODE: 409,
            ERROR_ID: "CONFLICT"
        },
        {
            ERROR_CODE: 410,
            ERROR_ID: "GONE"
        },
        {
            ERROR_CODE: 411,
            ERROR_ID: "LENGTH_REQUIRED"
        },
        {
            ERROR_CODE: 412,
            ERROR_ID: "PRECONDITION_FAILED"
        },
        {
            ERROR_CODE: 413,
            ERROR_ID: "PAYLOAD_TOO_LARGE"
        },
        {
            ERROR_CODE: 414,
            ERROR_ID: "URI_TOO_LONG"
        },
        {
            ERROR_CODE: 415,
            ERROR_ID: "UNSUPPORTED_MEDIA_TYPE"
        },
        {
            ERROR_CODE: 416,
            ERROR_ID: "RANGE_NOT_SATISFIABLE"
        },
        {
            ERROR_CODE: 417,
            ERROR_ID: "EXPECTATION_FAILED"
        },
        {
            ERROR_CODE: 418,
            ERROR_ID: "IM_A_TEAPOT"
        },
        {
            ERROR_CODE: 421,
            ERROR_ID: "MISDIRECTED_REQUEST"
        },
        {
            ERROR_CODE: 422,
            ERROR_ID: "UNPROCESSABLE_ENTITY"
        },
        {
            ERROR_CODE: 423,
            ERROR_ID: "LOCKED"
        },
        {
            ERROR_CODE: 424,
            ERROR_ID: "FAILED_DEPENDENCY"
        },
        {
            ERROR_CODE: 425,
            ERROR_ID: "TOO_EARLY"
        },
        {
            ERROR_CODE: 426,
            ERROR_ID: "UPGRADE_REQUIRED"
        },
        {
            ERROR_CODE: 428,
            ERROR_ID: "PRECONDITION_REQUIRED"
        },
        {
            ERROR_CODE: 429,
            ERROR_ID: "TOO_MANY_REQUESTS"
        },
        {
            ERROR_CODE: 431,
            ERROR_ID: "REQUEST_HEADER_FIELDS_TOO_LARGE"
        },
        {
            ERROR_CODE: 451,
            ERROR_ID: "UNAVAILABLE_FOR_LEGAL_REASONS"
        }
    ]
}
/** To be considered later */
// export const REFRESH_TOKEN_URLS = () => {
//     const authConfigService = inject(AuthConfigService);
//     return [
//         authConfigService.getTokenEndpoint(),
//         authConfigService.getRevokeEndpoint()
//     ]
// }