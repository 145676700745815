import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-header-socialmedia-icons',
  templateUrl: './header-socialmedia-icons.component.html',
  styleUrl: './header-socialmedia-icons.component.scss'
})
export class HeaderSocialmediaIconsComponent {
  public componentData: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
}
