import { Component, Input, OnInit } from '@angular/core';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { ProductListService } from '../../../../SGRE-custom/product-list-page/services/product-list.service';

@Component({
  selector: 'app-no-products-found',
  templateUrl: './no-products-found.component.html',
  styleUrl: './no-products-found.component.scss'
})
export class NoProductsFoundComponent implements OnInit {

  @Input() data: Observable<CmsParagraphComponent>;
  public productsList: Observable<any>;

  constructor(
    public component: CmsComponentData<any>,
    private productsService: ProductListService
  ) { }

  ngOnInit(): void {
    this.productsList = this.productsService.productsList$;
  }
}
