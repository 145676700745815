<div>
<div *ngIf="componentData.name === 'FaceBook'">
  <hr>
</div>
<div class="d-inline-flex">
  <div class="mr-4 d-inline-flex h1 text-cls" *ngIf="componentData.name === 'FaceBook'">Share
  </div>
  <div class="d-inline-flex">
  <div >
    <a href="{{componentData.url}}" class="h4 mb-0 nav-link secondary-anchor custom-align-center"><i
        class="fab fa-facebook-f"></i>
    </a>
  </div>
  <div >
    <a class="social-icon" target class="h4 mb-0 nav-link secondary-anchor custom-align-center" href="https://www.twitter.com/company/siemensgamesa">
      <i class="fa-brands fa-x-twitter"></i>
    </a>
  </div>
  <div >
    <a class="social-icon" class="h4 mb-0 nav-link secondary-anchor custom-align-center" href="https://www.instagram.com/company/siemensgamesa" role="button">
      <i class="fab fa-instagram"></i>
  </a>
  </div>
</div>
</div>
</div>
