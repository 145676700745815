import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, Subject, takeUntil, tap, timer } from 'rxjs';
import { TopProductInfo, Image } from '../../models/topProductInfo';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { HomepageService } from '../../services/homepage.service';

@Component({
  selector: 'app-homepage-top-products-section2',
  templateUrl: './homepage-top-products-section2.component.html',
  styleUrl: './homepage-top-products-section2.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageTopProductsSection2Component implements OnInit {

  protected featuredProductList: Observable<TopProductInfo>;
  public userLoggedIn: Observable<any>;
  public obsoleteFlag: Observable<any>;
  public quotationFlag: Observable<any>;
  public isLoggedIn: boolean = false;
  public showTopProducts: boolean = false;
  private unsubscribe$ = new Subject<void>();
  protected productsListData: any[];

  constructor(
    private homePageService: HomepageService,
    private globalService: GlobalService,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
    this.globalService.loadingSubject.next(true);
    this.getProductsList();
    this.showTopProducts = false;
    this.handleSubscriptions();
  }

  handleSubscriptions() {
    this.userLoggedIn = this.globalService.loginBanner$;
    this.globalService.topCategories$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.getProductsList();
      });
    this.globalService.loginBanner$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.isLoggedIn = data;
      });
  }

  getProductsList() {
    this.globalService.loadingSubject.next(true);
    this.homePageService.getFeaturedProductList()
    this.featuredProductList = this.homePageService.featuredProduct$.pipe(
      tap(data => {
        if (data && data.products.length > 0) {
          this.productsListData = data?.products;
          this.showTopProducts = true;
        } else {
          this.showTopProducts = false;
        }
        this.globalService.loadingSubject.next(false);
      })
    );
  }

  getProductUrl(urlVal: string): string {
    return this.globalService.getProductUrl(urlVal);
  }

  getImageUrl(imgArr: Image[]) {
    return (imgArr?.length > 0 && imgArr?.at(0)?.url)
      ? environment.siteUrls.getBackofficeUrl + imgArr.at(0).url
      : "../../../../assets/images_and_icons/image1.jpg";
  }

  addToCart(event) {
    let index = event.index;
    let paramsObject = {
      code: this.productsListData[index]?.code,
      quantity: event.quantity
    }
    let requestObject = {
      product: {
        code: this.productsListData[index]?.code,
      },
      quantity: event.quantity
    }
    let cartId = this.storageService.cartId;
    this.globalService.loadingSubject.next(true);
    this.globalService.addToCart(cartId, paramsObject, requestObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success: any) => {
          this.globalService.getCartsListByLegalEntity();
          timer(1500)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => this.homePageService.getFeaturedProductList());
        }, error: (error: any) => {
          this.globalService.loadingSubject.next(false);
        }
      });
  }

  getUpdatedMinQty(minQuantity, interval) {
    return this.globalService.getUpdatedMinQty(minQuantity, interval);
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
