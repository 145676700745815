import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  CmsComponentData,
  NavigationNode,
  NavigationService,
} from '@spartacus/storefront';
import {
  distinctUntilChanged,
  map,
  Observable,
  Subject,
} from 'rxjs';
import { CmsNavigationComponent } from '@spartacus/core';
import { MenuItem } from 'primeng/api';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-header-product-tab',
  templateUrl: './header-product-tab.component.html',
  styleUrls: ['./header-product-tab.component.scss'],
})
export class HeaderProductTabComponent implements OnInit, OnDestroy {

  data$: Observable<NavigationNode>;
  unsubscribe$ = new Subject<void>();
  items: MenuItem[] = [];
  item$: Observable<MenuItem[]>;

  constructor(
    private component: CmsComponentData<CmsNavigationComponent>,
    private navigationService: NavigationService,
    private storageService: StorageService,
    private router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.item$ = this.navigationService.getNavigationNode(this.component.data$)
      .pipe(
        distinctUntilChanged(),
        map((node) => this.transformNavigationNodes(node))
      );
  }

  private transformNavigationNodes(node: NavigationNode): MenuItem[] {
    const nodes: MenuItem[] = [];
    if (node?.children?.length > 0) {
      node.children.forEach((item) => {
        let routeUrl = item.url ? (typeof item.url === 'string' ? item.url : item.url.join()) : null
        const menuItem: MenuItem = {
          label: item.title,
          routerLink: routeUrl,
          command: () => this.checkAndReload(routeUrl)
        };
        // If the item has children, process them as sub-menu items
        if (item?.children?.length > 0) {
          menuItem.items = item.children.map((child) => {
            let routeUrl = child.url ? (typeof child.url === 'string' ? child.url : child.url.join()) : null;
            return {
              label: child.title,
              routerLink: routeUrl,
              command: () => routeUrl
            }
          });
        }
        if (this.shouldIncludeMenuItem(menuItem)) {
          nodes.push(menuItem);
        }
      });
    }
    return nodes;
  }

  checkAndReload(path: string) {
    this.router.navigate([path]).then(() => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([path]));
    });
  }

  private shouldIncludeMenuItem(menuItem: MenuItem): boolean {
    // const userRoles = this.storageService.userRoles;
    if (!menuItem.routerLink) {
      // Check sub-menu items if no routerLink on the main item
      if (menuItem?.items?.length > 0) {
        return menuItem.items.some(subItem => this.shouldIncludeMenuItem(subItem));
      }
      return false;
    }
    // if (menuItem?.routerLink?.includes(AppConstants.routeUrls.userManagement)) {
    //   const include = userRoles.includes('SGRE Admin') || userRoles.includes('Customer Admin');
    //   return include;
    // }
    return true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}