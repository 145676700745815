<div class="sg-social-footer-icons main-icon">
    <div class="d-flex">
        <div class="icon-cont custom-flex-center">
            <a class="social-icon" target="_blank" href="{{componentData.url}}">
              <i class="fa-brands fa-x-twitter"></i>
            </a>
        </div>
        <!-- <div class="icon-cont">
            <a class="social-icon" href="{{componentData.url}}" role="button">
                <i class="fab fa-facebook-f"></i>
            </a>
        </div>
        <div class="icon-cont">
            <a class="social-icon" href="{{componentData.url}}" role="button">
                <i class="fab fa-linkedin-in"></i>
            </a>
        </div>
        <div class="icon-cont">
            <a class="social-icon" href="{{componentData.url}}" role="button">
                <i class="fab fa-instagram"></i>
            </a>
        </div> -->
    </div>
</div>
