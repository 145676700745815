import { Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-not-found-link',
  templateUrl: './page-not-found-link.component.html',
  styleUrl: './page-not-found-link.component.scss'
})
export class PageNotFoundLinkComponent implements OnInit {

  public componentData$: Observable<any>;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.componentData$ = this.component.data$;
  }
}