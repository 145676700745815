<app-homepage-image-section5 *ngIf="componentData.uid === 'Section5ImageComponent'"></app-homepage-image-section5>

<app-homepage-image-section6 *ngIf="componentData.uid === 'Section6ImageComponent'"></app-homepage-image-section6>

<app-homepage-image-section7 *ngIf="componentData.uid === 'Section7ImageComponent'"></app-homepage-image-section7>

<app-homepage-image-section8 *ngIf="componentData.uid === 'Section8ImageComponent'"></app-homepage-image-section8>

<app-homepage-image-section9 *ngIf="componentData.uid === 'Section9ImageComponent'"></app-homepage-image-section9>

<app-footer-image *ngIf="componentData.uid === 'SGREFooterImageComponent'"></app-footer-image>
