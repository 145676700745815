import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { Observable, Subject, filter, takeUntil } from 'rxjs';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { Router } from '@angular/router';
import { SharedCheckoutService } from '../../../checkout/services/sharedcheckout.service';
import { ProductListService } from '../../../product-list-page/services/product-list.service';
import { CmsService, isNotNullable, isNotUndefined } from '@spartacus/core';

@Component({
  selector: 'app-legal-entity-selector',
  templateUrl: './legal-entity-selector.component.html',
  styleUrl: './legal-entity-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalEntitySelectorComponent implements OnInit, OnDestroy {

  public legalEntityList: any[];
  public legalEntityListUid: string[];
  public legalEntityListSession: string[];
  public selectedLegalEntitySession: any;
  public selectedLegalEntity: Observable<any>;
  public legalEntityListNew: Observable<any>;
  public searchText: string = '';
  public userLoggedIn: Observable<any>;
  private isCheckoutPage = false;
  private unsubscribe$ = new Subject<void>();
  public searchLegalEntity: string = "Search Legal Entity";

  constructor(
    private globalService: GlobalService,
    private storageService: StorageService,
    private router: Router,
    private sharedCheckoutService: SharedCheckoutService,
    private productsService: ProductListService,
    private cmsService: CmsService
  ) { }

  ngOnInit(): void {
    this.userLoggedIn = this.globalService.loginBanner$;

    this.legalEntityListNew = this.globalService.legalEntitiesList$;
    this.selectedLegalEntity = this.globalService.defaultLegalEntitySubject$;

    this.legalEntityListSession = this.storageService.userLegalEntities
      ? this.storageService.userLegalEntities?.map((item) => item?.name)
      : [];
    this.selectedLegalEntitySession = this.storageService.defaultLegalEntity
      ? this.storageService.defaultLegalEntity
      : '';
    this.sharedCheckoutService.isCheckoutPage$
      .pipe(
        filter(isNotNullable),
        filter(isNotUndefined),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((isCheckout) => {
        this.isCheckoutPage = isCheckout;
      });
  }

  onValueChange(legalEntity: string) {
    this.legalEntityListSession = this.storageService.userLegalEntities
      ? this.storageService.userLegalEntities?.map((item) => item?.name)
      : [];
    if (legalEntity !== this.storageService?.defaultLegalEntity?.name && this.legalEntityListSession?.length > 0) {
      let index = this.legalEntityListSession.indexOf(legalEntity);
      this.globalService.updateLegalEntity(index);
      if (this.isCheckoutPage) {
        this.router.navigate([AppConstants.routeUrls.plp]);
      }
    }
    this.searchText = '';
    if (this.router.url.includes(AppConstants.routeUrls.plp)) {
      this.productsService.paginationReset.next(true);
    }
    this.cmsService.refreshLatestPage();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
