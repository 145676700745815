<div class="row searchbar-comp">
  <div id="8" class="component SearchBar col-12 mb-2">
    <div class="row">
      <div [ngClass]="((userLoggedIn | async) && this.displayCarts) || checkAccess() ? 'col-md-7 col-lg-8 col-xl-9' : 'col-12'">
        <form [formGroup]="searchForm">
          <div class="search-bar-wrapper">
            <div class="search-bar-input">
              <p-autoComplete
                styleClass="cstm-autocomplete"
                placeholder="{{'homePageHeader.searchProductCatalog' | cxTranslate}}"
                formControlName="search"
                [group]="true"
                [delay]="1000"
                [minLength]="3"
                [suggestions]="(searchSuggestions | async)"
                (completeMethod)="filterSearch($event)"
                [scrollHeight]="'400px'"
                (onSelect)="onSelect($event)"
                (onFocus)="handleFocus()"
                (keydown.enter)="handleEnter()"
                [emptyMessage]="'No matching results were found.'"
                #autocomplete
              >
                <ng-template let-group pTemplate="group">
                  <div class="flex align-items-center">
                    <span>{{ group.label }}</span>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
            <button class="search-bar-btn" (click)="updateProductsList()">
              <i class="pi pi-search"></i>
            </button>
          </div>
        </form>
      </div>
      <div *ngIf="((userLoggedIn | async) && this.displayCarts) || checkAccess()" class="col-md-5 col-lg-4 col-xl-3 minicart-container">
        <cx-page-slot position="MiniCart"></cx-page-slot>
      </div>
    </div>
  </div>
</div>
