import { Component, Input } from '@angular/core';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-no-request-found',
  templateUrl: './no-request-found.component.html',
  styleUrl: './no-request-found.component.scss'
})
export class NoRequestFoundComponent {
  public componentData: any;
  @Input() data: Observable<CmsParagraphComponent>;
  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });  
  }
}
