import { GlobalMessageConfig, GlobalMessageType } from "@spartacus/core";

export const customGlobalMessageConfig: GlobalMessageConfig = {
    globalMessages: {
        [GlobalMessageType.MSG_TYPE_CONFIRMATION]: {
            timeout: 3000,
        },
        [GlobalMessageType.MSG_TYPE_INFO]: {
            timeout: 3000,
        },
        [GlobalMessageType.MSG_TYPE_ERROR]: {
            timeout: 100000000000,
        },
        [GlobalMessageType.MSG_TYPE_WARNING]: {
            timeout: 100000000000,
        },
        [GlobalMessageType.MSG_TYPE_ASSISTIVE]: {
            timeout: 7000,
        },
    },
};
