import { Component, OnInit } from '@angular/core';
import { CmsBannerComponent, CmsBannerComponentMedia } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { environment } from '../../../../../environments/environment';
import { Observable, map } from 'rxjs';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';

@Component({
  selector: 'app-sitelogo-header',
  templateUrl: './sitelogo-header.component.html',
  styleUrl: './sitelogo-header.component.scss'
})
export class SitelogoHeaderComponent implements OnInit {

  url$: Observable<string>;
  homepageUrl = AppConstants.routeUrls.home;

  constructor(
    public component: CmsComponentData<any>
  ) { }

  ngOnInit(): void {
    this.url$ = this.component.data$
      .pipe(
        map((data: CmsBannerComponent) => {
          let media: CmsBannerComponentMedia = data?.media as CmsBannerComponentMedia;
          return environment.siteUrls.getBackofficeUrl + media?.url;
        })
      );
  }
}
