import { NgModule } from '@angular/core';
import { CommonModule, JsonPipe } from '@angular/common';
import { SGRESharedModule } from '../../../SGRE-shared/SGRE-shared.module';
import { ConfigModule } from '@spartacus/core';
import { PrivacyPolicyContainer1Component } from '../Components/privacy-policy-container1/privacy-policy-container1.component';
import { PrivacyPolicyButtonsSection3Component } from '../Components/privacy-policy-buttons-section3/privacy-policy-buttons-section3.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        // CMSTabParagraphContainer:{
        //   component: PrivacyPolicyContainer1Component
        // },
        // CMSTabParagraphContainer: {
        //   component: PrivacyPolicyContainer1Component,
        // },
        CMSTabParagraphComponent:{
          component: () => import('../Components/privacy-policy-container1/privacy-policy-container1.component').then(m => m.PrivacyPolicyContainer1Component),
        },
        PrivacyPolicySection3Component: {
          component: () => import('../Components/privacy-policy-buttons-section3/privacy-policy-buttons-section3.component').then(m => m.PrivacyPolicyButtonsSection3Component),
        },
      }
    })
  ],
})
export class PrivacyPolicyModule { }
