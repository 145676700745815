<div [innerHTML]="componentData.content" class="container-fluid"></div>

<!-- Content for External Users -->
<!-- <div class="row">
    <div class="col-md-3">
        <div class="parapgraph-data">
            <label class="profile-label">
                <i class="sg-icon fa fa-cart-plus"></i>Requester
            </label>
            <p class="profile-data">A requester can create, edit and submit carts</p>
        </div>
    </div>
    <div class="col-md-3">
        <div class="parapgraph-data">
            <label class="profile-label">
                <i class="fa-solid fa-id-card"></i>
                Purchaser
            </label>
            <p class="profile-data">A purchaser can approve and complete orders</p>
        </div>
    </div>
    <div class="col-md-3">
        <div class="parapgraph-data">
            <label class="profile-label">
                <div>
                    <img class="key-icon" src="assets/images_and_icons/SG-icons-key-purple.png"><span
                        class="ml-2">Customer Admin</span>
                </div>
            </label>
            <p class="profile-data">A Customer Admin can administrate Customer Users and their Roles</p>
        </div>
    </div>
</div> -->

<!-- Content for Internal Users -->
<!-- <div class="row">
    <div class="col-md-6">
        <div class="parapgraph-data">
            <label class="profile-label">
                <i class="fa-solid fa-id-card"></i>Sales Rep
            </label>
            <p class="profile-data">A Sales Rep can see order histories for assigned legal entities
            </p>
        </div>
    </div>
</div> -->