

       <div style="padding-left: 15px; padding-right: 15px;" class="row">
          <div class="component image file-type-icon-media-link col-12">
             <div class="component-content">
                <a href="#"><img class="sec6-img" src="{{imageUrl}}" alt="Eshop page" width="100%" height="100%"  data-variantfieldname="Image"></a><span class="image-caption field-imagecaption"></span>
             </div>
          </div>
       </div>


