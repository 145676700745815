
   <div  class="row padding-bet">
      <div class="component image file-type-icon-media-link col-12">
         <div class="component-content">
            <a href="#"><img src="{{imageUrl}}" alt="Technicians on the site" width="100%" height="100%" class="sec8-img" data-variantfieldname="Image"></a><span class="image-caption field-imagecaption"></span>
         </div>
      </div>
   </div>



