import { Component, ElementRef, ViewChild, viewChild } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-faq-access-request-video',
  templateUrl: './faq-access-request-video.component.html',
  styleUrl: './faq-access-request-video.component.scss'
})
export class FaqAccessRequestVideoComponent {
  @ViewChild('videoPlayer') el: ElementRef;
  videoPlayer: ElementRef<HTMLVideoElement>;
  public componentData: any;
  videoUrl: string;

  constructor(
    public component: CmsComponentData<any>,
    private elRef:ElementRef,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
      // this.videoUrl = environment.siteUrls.getBackofficeUrl + data?.video?.url;
    });
  }

  ngAfterViewInit(): void {
    const modalElement = this.elRef.nativeElement.querySelector('#closeVideo');
    modalElement.addEventListener('hidden.bs.modal', () =>{
      this.stopVideo();
    });
  }

  stopVideo(): void {
    const video: HTMLVideoElement = this.videoPlayer.nativeElement;
    video.pause();
    video.currentTime=0;
  }

}
