import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { StyleClassModule } from 'primeng/styleclass';
import { InputTextModule } from 'primeng/inputtext';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputIconModule } from 'primeng/inputicon';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MenubarModule } from 'primeng/menubar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { DragDropModule } from 'primeng/dragdrop';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RippleModule,
    ButtonModule,
    AutoCompleteModule,
    PaginatorModule,
    BreadcrumbModule,
    MessagesModule,
    ToastModule,
    StyleClassModule,
    InputTextModule,
    TableModule,
    InputNumberModule,
    InputIconModule,
    RadioButtonModule,
    CheckboxModule,
    InputGroupModule,
    InputGroupAddonModule,
    DialogModule,
    AccordionModule,
    DropdownModule,
    MultiSelectModule,
    OverlayPanelModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    MenubarModule,
    DynamicDialogModule,
    DragDropModule,
    FileUploadModule,
    CalendarModule
  ],
  exports: [
    RippleModule,
    ButtonModule,
    AutoCompleteModule,
    PaginatorModule,
    BreadcrumbModule,
    MessagesModule,
    ToastModule,
    StyleClassModule,
    InputTextModule,
    TableModule,
    InputNumberModule,
    InputIconModule,
    RadioButtonModule,
    CheckboxModule,
    InputGroupModule,
    InputGroupAddonModule,
    DialogModule,
    AccordionModule,
    DropdownModule,
    MultiSelectModule,
    OverlayPanelModule,
    KeyFilterModule,
    ProgressSpinnerModule,
    MenubarModule,
    DynamicDialogModule,
    DragDropModule,
    FileUploadModule,
    CalendarModule
  ]
})
export class PrimengModule { }
