import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { GlobalService } from '../../../services/global.service';
import { environment } from '../../../../../environments/environment';
import { Observable, Subject, takeUntil, timer } from 'rxjs';

@Component({
  selector: 'app-homepage-video-section4',
  templateUrl: './homepage-video-section4.component.html',
  styleUrl: './homepage-video-section4.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomepageVideoSection4Component {

  public isLoggedIn$: Observable<boolean>;
  public componentData: any;
  videoUrl: string;
  thumbnailUrl: string;
  showPoster: boolean = true;

  @ViewChild('videoPlayer') videoPlayer!: ElementRef<HTMLVideoElement>
  unsubscribe$ = new Subject<void>();

  constructor(
    public component: CmsComponentData<any>,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
      this.videoUrl = environment.siteUrls.getBackofficeUrl + data?.video?.url;
      this.thumbnailUrl = environment.siteUrls.getBackofficeUrl + data?.thumbnail?.url;
    });
    this.isLoggedIn$ = this.globalService.loginBanner$;
  }

  playVideo() {
    this.showPoster = false;
    timer(0)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        const video: HTMLVideoElement = this.videoPlayer.nativeElement;
        video.play().then(() => { });
      });
  }

  onVideoEnd() {
    this.showPoster = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }
}
