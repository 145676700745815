import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-details-modalpopup',
  templateUrl: './order-details-modalpopup.component.html',
  styleUrl: './order-details-modalpopup.component.scss'
})
export class OrderDetailsModalpopupComponent {

  @Input() modalpopup;
}
