<div class="container-fluid">
  <div class="col-12">
    <p [innerHTML]="componentData.content | trust: 'html'"></p>
  </div>
</div>

<!-- <div class="container-fluid">
  <div class="col-12">
    <div class="mb-4">
      <div class="qn-heading">How can we help you?</div>
      <div>
        <div>If you want to know more about Siemens Gamesa, or if you have any questions or</div>
        <div>requests, please don't hesitate to contact us.</div>
      </div>
    </div>
  </div>
</div> -->

<!-- EXAMPLE - 1  -->
<!-- <div class="form-group">
  <div class="qn-heading">How can we help you?</div>
  <div class="mb-3">If you want to know more about Siemens Gamesa, or if you have any questions or<br>
    requests,please don't hesitate to contact us.</div>
  <div class="form-group">
    <input type="text" pInputText [(ngModel)]="value" />
  </div>
</div> -->

<!-- <div class=\"form-group\">
  <div class=\"qn-heading\">How can we help you?</div>
  <div style=\"mb-3\">If you want to know more about Siemens Gamesa, or if you have any questions or<br> requests,please
    don't hesitate to contact us.</div>
  <div class=\"form-group\"> <input type=\"text\" pInputText [(ngModel)]=\"value\" /> </div>
</div> -->


<!-- EXAMPLE - 2 -->
<!-- <div class="form-group">
  <div class="qn-heading">How can we help you?</div>
  <div class="mb-3">If you want to know more about Siemens Gamesa, or if you have any questions or<br>
    requests,please don't hesitate to contact us.</div>
  <div class="form-group">
    <select name="cars" id="cars" class="custom-dropdown">
      <option value="volvo">Volvo</option>
      <option value="saab">Saab</option>
      <option value="mercedes">Mercedes</option>
      <option value="audi">Audi</option>
    </select>
    <input type="text" placeholder="enter something here" />
  </div>
</div> -->

<!-- <div class=\"form-group\">
  <div class=\"qn-heading\">How can we help you?</div>
  <div style=\"mb-3\">If you want to know more about Siemens Gamesa, or if you have any questions or<br> requests,please
    don't hesitate to contact us.</div>
  <div class=\"form-group\">
    <select name=\"cars\" id=\"cars\">
      <option value=\"volvo\">Volvo</option>
      <option value=\"saab\">Saab</option>
      <option value=\"mercedes\">Mercedes</option>
      <option value=\"audi\">Audi</option>
    </select>
    <input type=\"text\" placeholder=\"enter something here\" />
  </div>
</div> -->


<!-- EXAMPLE - 3 -->
<!-- <div>
  <div class="qn-heading">How can we help you?</div>
  <div class="mb-3">If you want to know more about Siemens Gamesa, or if you have any questions or<br>
    requests,please don't hesitate to contact us.</div>
  <div>
    <select name="cars" id="cars" class="custom-dropdown">
      <option value="volvo">Volvo</option>
      <option value="saab">Saab</option>
      <option value="mercedes">Mercedes</option>
      <option value="audi">Audi</option>
    </select>
    <input type="text" placeholder="enter something here" />
    <button (click)="onSubmit()">Submit</button>
  </div>
</div> -->

<!-- <div>
  <div class=\"qn-heading\">How can we help you?</div>
  <div style=\"mb-3\">If you want to know more about Siemens Gamesa, or if you have any questions or<br> requests,please
    don't hesitate to contact us.</div>
  <div>
    <select name=\"cars\" id=\"cars\" class=\"custom-dropdown\">
      <option value=\"volvo\">Volvo</option>
      <option value=\"saab\">Saab</option>
      <option value=\"mercedes\">Mercedes</option>
      <option value=\"audi\">Audi</option>
    </select>
    <input type=\"text\" placeholder=\"enter something here\" />
    <button (click)=\"onSubmit()\">Submit</button>
  </div>
</div> -->