import { Component, OnInit, ViewChild, ChangeDetectorRef, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ProductListService } from '../../../product-list-page/services/product-list.service';
import { Observable, Subject, distinctUntilChanged, filter, of, takeUntil, tap } from 'rxjs';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { AutoComplete, AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { NavigationEnd, Router } from '@angular/router';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-box-header',
  templateUrl: './search-box-header.component.html',
  styleUrl: './search-box-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchBoxHeaderComponent implements OnInit, OnDestroy {

  public userLoggedIn: Observable<any>;
  public displayCarts: boolean;
  public userRoles: any[];
  public searchForm: FormGroup;
  public productsList: any;
  public searchedQuery: string;
  public searchSuggestions: Observable<any[]>;
  private unsubscribe$ = new Subject<void>();
  userId: string;
  public legalEntityListSession: string[];
  public selectedLegalEntitySession: any;
  allowToSearch: boolean = true;
  retainQueryFlag: boolean;

  @ViewChild('autocomplete', { static: false }) autocomplete: AutoComplete;

  get search() {
    return this.searchForm.get('search');
  }

  constructor(
    private fb: FormBuilder,
    private productsService: ProductListService,
    private globalService: GlobalService,
    private storageService: StorageService,
    private cdRef: ChangeDetectorRef,
    private router: Router
  ) {
    this.searchForm = this.fb.group({
      search: ['']
    })
  }

  ngOnInit(): void {
    this.userLoggedIn = this.globalService.defaultLegalEntitySubject$
      .pipe(
        tap(data => this.displayCarts = this.checkAccess())
      )
    this.searchSubscription();
    this.clearSearchOnRouteChange();
    this.userId = this.globalService.getUserId();
    this.legalEntityListSession = this.storageService.userLegalEntities
      ? this.storageService.userLegalEntities?.map((item) => item?.name)
      : [];
    this.selectedLegalEntitySession = this.storageService.defaultLegalEntity
      ? this.storageService.defaultLegalEntity
      : '';
    if (this.selectedLegalEntitySession.name || this.userId == 'anonymous') {
      this.allowToSearch = true;
    } else {
      this.allowToSearch = false;
    }
  }

  clearSearchOnRouteChange() {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: NavigationEnd) => {
        if (!this.retainQueryFlag) {
          this.searchForm.reset();
          // this.storageService.query = '';
          // localStorage.removeItem(AppConstants.SessionStorageKeys.query);
        } else { this.retainQueryFlag = false; }
      });
  }

  checkAccess(): boolean {
    this.userRoles = this.storageService.userRoles;
    return this.userRoles?.includes("Requester");
  }

  searchSubscription() {
    this.productsService.searchData$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (data: any) => {
          this.productsList = data;
          const searchProductsList = (this.productsList?.products) ? this.productsList.products : [];
          const productNames = searchProductsList?.map(obj => ({
            label: obj.name,
            value: obj.name,
            url: obj.url,
            category: 'Products'
          }));
          const productCodes = searchProductsList?.map(obj => ({
            label: obj.baseProduct,
            value: obj.baseProduct,
            url: obj.url,
            category: 'Part Numbers'
          }));
          const categoryValues = (this.productsList?.facets)
            ? this.productsList.facets.filter(facet => facet.name === 'allCategories')?.at(0)?.values?.map(item => ({
              label: item.name,
              value: item.name,
              query: item.query.query.value.substring(item.query.query.value.indexOf(":")),
              category: 'Categories'
            })).slice(0, 5)
            : [];
          let results: any[] = []
          if (productNames?.length > 0) {
            results.push({ label: 'Products', items: productNames });
          }
          if (productCodes?.length > 0) {
            results.push({ label: 'Part Numbers', items: productCodes });
          }
          if (categoryValues?.length > 0) {
            results.push({ label: 'Categories', items: categoryValues });
          }
          this.searchSuggestions = of([...results]);
          this.cdRef.markForCheck();
        }
      });
  }

  filterSearch(event: AutoCompleteCompleteEvent) {
    const query = event.query;
    this.searchedQuery = query;
    this.productsService.searchTextboxQuery = query;
    if (this.allowToSearch) {
      this.productsService.getProductsList(
        { query: this.searchedQuery, currentPage: 0, pageSize: 5, fields: "FULL" },
        true
      );
    } else {
      // When user doesnt have LE the state when searching is not allowed
      this.searchSuggestions = of([]);
    }
  }

  handleEnter() {
    if (!this.router.url.includes(AppConstants.routeUrls.plp)) {
      this.storageService.query = this.search.value;
      this.retainQueryFlag = true;
      this.router.navigate([AppConstants.routeUrls.plp]);
    }
  }

  onSelect(event: any) {
    this.search.setValue(event?.value?.value);
    const selectedResult = event?.value;
    const category = selectedResult?.category;
    if (category === 'Products' || category === 'Part Numbers') {
      this.search.setValue('');
      this.router.navigate([this.globalService.getProductUrl(selectedResult.url)]);
    } else if (category === 'Categories') {
      this.search.setValue('');
      this.updateProductsList(":name-asc:allCategories:" + `"${selectedResult.value}"`, true);
    }
  }

  updateProductsList(queryText = this.search.value, click = false) {
    if (!this.router.url.includes(AppConstants.routeUrls.plp)) {
      this.storageService.query = queryText;
      this.retainQueryFlag = true;
      this.router.navigate([AppConstants.routeUrls.plp]);
    } else if ((queryText?.length >= 3 || queryText?.length == 0) && this.productsService.searchQuery !== queryText) {
      this.productsService.searchQuery = queryText;
      this.productsService.paginationReset.next(true);
      this.productsService.getProductsList({ query: queryText + (!click ? this.productsService.filterQuery : ''), currentPage: 0, pageSize: 30, fields: "FULL" });
    }
  }

  handleFocus() {
    if (this.search.value === this.searchedQuery) {
      this.autocomplete.show();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
