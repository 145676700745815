<div class="card flex justify-content-center">
    <p-dropdown [options]="countries" optionLabel="name" [filter]="true" filterBy="name" [(ngModel)]="selectedCountry"
        [showClear]="true" placeholder="Select a Country">
        <ng-template pTemplate="selectedItem" let-selectedOption>
            <div class="flex align-items-center gap-2">
                <div>{{selectedOption.name}}</div>
            </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
            <div class="flex align-items-center gap-2">
                <div>{{country.name}}</div>
            </div>
        </ng-template>
    </p-dropdown>
</div>