<ng-container [ngSwitch]="(data$ | async)?.uid">
  <ng-container *ngSwitchCase="'NoticeTextParagraphComponent'">
    <app-footer-notice [data]="data$"></app-footer-notice>
  </ng-container>
  <ng-container *ngSwitchCase="'NoProductFoundParagraphComponent'">
    <app-no-products-found [data]="data$"></app-no-products-found>
  </ng-container>
  <ng-container *ngSwitchCase="'UploadViewParagraphComponent'">
    <app-bulkorder-upload-paragraph [data]="data$"></app-bulkorder-upload-paragraph>
  </ng-container>
  <ng-container *ngSwitchCase="'UploadViewNoteParagraphComponent'">
    <app-bulkorder-upload-type-paragraph [data]="data$"></app-bulkorder-upload-type-paragraph>
  </ng-container>
  <ng-container *ngSwitchCase="'DownloadViewParagraphComponent'">
    <app-bulkorder-download-paragraph [data]="data$"></app-bulkorder-download-paragraph>
  </ng-container>
  <ng-container *ngSwitchCase="'SubmitViewProductFoundNoteParagraphComponent'">
    <app-bulkorder-product-found-paragraph [data]="data$"></app-bulkorder-product-found-paragraph>
  </ng-container>
  <ng-container *ngSwitchCase="'SubmitViewProductNotFoundNoteParagraphComponent'">
    <app-bulkorder-product-notfound-paragraph [data]="data$"></app-bulkorder-product-notfound-paragraph>
  </ng-container>
  <ng-container *ngSwitchCase="'UserProfileInternalRoleParagraphComponent'">
    <app-profile-paragraph [data]="data$"></app-profile-paragraph>
  </ng-container>
  <ng-container *ngSwitchCase="'UserProfileExternalRoleParagraphComponent'">
    <app-profile-paragraph [data]="data$"></app-profile-paragraph>
  </ng-container>
  <ng-container *ngSwitchCase="'EmptyOrderRequestsParagraphComponent'">
    <app-no-request-found [data]="data$"></app-no-request-found>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml((data$ | async)?.content)" class="anonymous-user-container"></div>
  </ng-container>
</ng-container>