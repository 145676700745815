import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-homepage-image-section6',
  templateUrl: './homepage-image-section6.component.html',
  styleUrl: './homepage-image-section6.component.scss'
})
export class HomepageImageSection6Component {
  public componentData: any;
  imageUrl: string;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
      this.imageUrl = environment.siteUrls.getBackofficeUrl + data?.media?.url?.split('medias/')[1];
    });
  }
}
