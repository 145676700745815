import { Component } from '@angular/core';
import { CmsLinkComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-footer-contact-us',
  templateUrl: './footer-contact-us.component.html',
  styleUrl: './footer-contact-us.component.scss'
})
export class FooterContactUsComponent {

  public componentData: Observable<CmsLinkComponent>;

  constructor(private component: CmsComponentData<CmsLinkComponent>) { }

  ngOnInit(): void {
    this.componentData = this.component.data$;
  }
}
