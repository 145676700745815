import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import { ReactiveFormsModule } from '@angular/forms';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';

import { ContentLeftComponent } from './components/content-left/content-left.component';
import { DownloadFileComponent } from './components/download-file/download-file.component';
import { NextButtonComponent } from './components/next-button/next-button.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { ViewProductTableComponent } from './components/view-product-table/view-product-table.component';

import { ViewTabeNotFoundComponent } from './components/view-tabe-not-found/view-tabe-not-found.component';
import { ViewCartNameComponent } from './components/view-cart-name/view-cart-name.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';


export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}

@NgModule({
  declarations: [
    ContentLeftComponent,
    DownloadFileComponent,
    NextButtonComponent,
    UploadFileComponent,
    ViewProductTableComponent,
    ViewTabeNotFoundComponent,
    ViewCartNameComponent,
  ],
  imports: [
    CommonModule,
    SGRESharedModule,
    PrimengModule,
    AngularMaterialModule,
    PageSlotModule,
    ReactiveFormsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        BulkOrderViewSelectionComponent: {
          component: () => import('./components/content-left/content-left.component').then(m => m.ContentLeftComponent),
          guards: [CustomRouteGuard]
        },
        BulkOrderDownloadExcelButtonComponent: {
          component: () => import('./components/download-file/download-file.component').then(m => m.DownloadFileComponent),
          guards: [CustomRouteGuard]
        },
        BulkOrderUploadFileComponent: {
          component: () => import('./components/upload-file/upload-file.component').then(m => m.UploadFileComponent),
          guards: [CustomRouteGuard]
        },
        SubmitViewCartNameFieldComponent: {
          component: () => import('./components/view-cart-name/view-cart-name.component').then(m => m.ViewCartNameComponent),
          guards: [CustomRouteGuard]
        },
        SubmitViewProductFoundTableComponent: {
          component: () => import('./components/view-product-table/view-product-table.component').then(m => m.ViewProductTableComponent),
          guards: [CustomRouteGuard]
        },
        SubmitViewProductNotFoundTableComponent: {
          component: () => import('./components/view-tabe-not-found/view-tabe-not-found.component').then(m => m.ViewTabeNotFoundComponent),
          guards: [CustomRouteGuard]
        },
        NextAndCancelButtonComponent: {
          component: () => import('./components/next-button/next-button.component').then(m => m.NextButtonComponent),
          guards: [CustomRouteGuard]
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule
  ],
  providers: [
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class BulkOrderPageModule {

}
