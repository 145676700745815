import { LayoutConfig } from '@spartacus/storefront';

export const customLayoutConfig: LayoutConfig = {
  layoutSlots: {
    header: {
      lg: {
        slots: ['HeaderContainer'],
      },
      xs: {
        slots: ['SiteLogo', 'PreHeader', 'SearchBox', 'AnonymousSignInBanner'],
      },
    },
    navigation: {
      lg: { slots: ['PreHeader', 'NavigationBar'] },
      slots: [
        'SignIn',
        'NavigationBar',
        'HeaderLinks',
        'LanguageSelector',
        'ContactUs',
        'LegalEntitySelector',
        'SocialMediaLinkHeader',
        'SearchBox',
        'AnonymousSignInBanner',
      ],
    },
    LandingPage2Template: {
      lg: {
        slots: [
          'Section1',
          'Section2',
          'Section3',
          'Section4',
          'Section5A',
          'Section5B',
          'Section6',
          'Section7',
          'Section8',
          'Section9',
        ],
      },
      slots: [
        'Section1',
        'Section2',
        'Section3',
        'Section4',
        'Section5A',
        'Section5B',
        'Section6',
        'Section7',
        'Section8',
        'Section9',
      ],
    },
    ContentPage1Template: {
      slots: [
        'BodyContent',
        'Section2Aproducts',
        'Section2Aorders',
        'Section2Borders',
        'Section2Aquotations',
        'Section2Aaccessrequests',
        'Section2BordersQ1',
        'Section2Baccessrequests',
        'Section2Bquotations',
        'Section2Bproducts',
        'Section1',
        'Section2',
        'Section3',
        'Section4',
      ],
    },
    ProductGridPageTemplate: {
      slots: ['ProductLeftRefinementSlot', 'ProductGrid'],
    },
    ProductDetailsPageTemplate: {
      slots: ['SummaryA', 'SummaryB', 'AlternateProduct', 'RelatedProduct'],
    },
    CartPageTemplate: {
      slots: ['CenterLeftContent', 'TableContent', 'BottomContent'],
    },
    InviteUserPageTemplate: {
      slots: ['BodyContent'],
    },
    AccountPageTemplate: {
      slots: [
        'TopContent',
        'TopContentA',
        'TopContentB',
        'MiddleContent',
        'BottomContentMyProfile',
        'TableContent',
        'BottomContentOrderDetails',
      ],
    },
    SavedCartsPageTemplate: {
      slots: ['TopContent', 'BodyContent', 'MiddleContent', 'BottomContent'],
    },
    BulkOrderPageTemplate: {
      slots: [
        'CenterLeftContent',
        'DownloadView',
        'UploadView',
        'SubmitView',
        'BottomContent',
      ],
    },
    UsersPageTemplate: {
      slots: ['TopContent', 'BodyContent'],
    },
    RequestAccessPageTemplate: {
      slots: ['BodyContent'],
    },
    OrderHistoryPageTemplate: {
      slots: ['Tabs', 'TopContent', 'BodyContent', 'BottomContent'],
    },
    OrderRequestsPageTemplate: {
      slots: ['BodyContent'],
    },
    MultiStepCheckoutSummaryPageTemplate: {
      slots: ['TopContent', 'BodyContent', 'BottomContent'],
    },
    footer: {
      lg: {
        slots: ['FooterContainer', 'FooterScrollToTop'],
      },
      slots: ['FooterContainer', 'FooterScrollToTop'],
    },
    ErrorPageTemplate: {
      slots: ['MiddleContent', 'BottomContent'],
    },
  },
};
