import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { StorageService } from '../services/storage.service';

@Pipe({
  name: 'formatDate',
  pure: false
})
export class FormatDatePipe implements PipeTransform {

  formatValue: any = 'MM/dd/yyyy'

  constructor(
    private datePipe: DatePipe,
    private storageService: StorageService
  ) { }

  transform(value: any, ...args: unknown[]): unknown {
    this.formatValue = this.storageService.dateFormat;
    return this.datePipe.transform(value, this.formatValue)
  }

}
