    <ng-container *ngIf="currentpage$ | async as page ">
        <div class="px-3" *ngIf="page===3 && (productLength>0)">
            <h3>{{ "bulkOrder.productsNotFound" | cxTranslate }}</h3>
            <div *ngIf="foundProductLength">
                <div role="alert" class="alert fade show alert-danger my-2">
                    <p>{{"bulkOrder.no-valid-data" |cxTranslate}} </p>
                </div>
            </div>
            <div [innerHTML]="componentData.content"></div>
        </div>
    </ng-container>
