import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-terms-of-use-social-media',
  templateUrl: './terms-of-use-social-media.component.html',
  styleUrl: './terms-of-use-social-media.component.scss'
})
export class TermsOfUseSocialMediaComponent {
  public componentData: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });

  }
}
