<div>
    <div class="row">
        <div class="component col-12 py-2">
            <div>
                <h2 *ngIf="showTopProducts" class="featured-products-heading">  {{"homePageHeader.topFeaturedProducts"|cxTranslate}} </h2>
                <div *ngIf="featuredProductList | async as productList" class="mb-3">
                    <div class="col-12 pl-0 pr-0">
                        <div class="row m-0">
                            <div class="col-md-6 col-lg-4 col-xl-3 mb-3 px-2 align-items-stretch"
                                *ngFor="let product of productList?.products; let i = index;"> <!-- slice:0:3 -->
                                <app-card-template
                                    [index]="i"
                                    (addToCartClk)="addToCart($event)"
                                    [productId]="product?.baseProduct"
                                    [productName]="product?.name"
                                    [productPrice]="product?.discountPriceData.value"
                                    [currency]="product?.discountPriceData.currencyIso"
                                    [userLoggedIn]="!(userLoggedIn | async)"
                                    [minimumQty]="getUpdatedMinQty(product?.minQuantity, product?.interval)"
					                [maximumQty]="product?.maxQuantity > 0 ? product?.maxQuantity : 1000"
                                    [productQuantity]="product?.minQuantity > 0 ? product.minQuantity : 1"
                                    [qtyStep]="product?.interval > 0 ? product?.interval : 1"
                                    [productNavigateUrl]="getProductUrl(product?.url)"
                                    [obsoleteFlag]="product?.obsoleteFlag"
                                    [quotationFlag]="product?.quotationFlag"
                                    [productImageUrl]="getImageUrl(product?.images)">
                                </app-card-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>