import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Subject, takeUntil, tap } from 'rxjs';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';

declare let bootstrap: any;

@Component({
  selector: 'app-cart-selector',
  templateUrl: './cart-selector.component.html',
  styleUrl: './cart-selector.component.scss'
})
export class CartSelectorComponent implements OnInit, OnDestroy {

  public saveCartForm: FormGroup;
  public cartsList$: Observable<any>;
  public toggleCarts = false;
  public fromToggle = false;
  public legalEntityList: any[];
  public legalEntityListUid: any[];
  public modalInstance: any;
  public unsubscribe$ = new Subject<void>();
  @ViewChild('cartModal') cartModal: ElementRef;

  get cartName() { return this.saveCartForm.get('cartName'); }
  get legalEntity() { return this.saveCartForm.get('legalEntity'); }

  @HostListener('document:click', ['$event']) onDocument(event: MouseEvent) {
    if (this.toggleCarts && !this.fromToggle) this.toggleCarts = !this.toggleCarts;
    this.fromToggle = false;
  }

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private storageService: StorageService
  ) {
    this.saveCartForm = new FormGroup({
      cartName: new FormControl('', [Validators.required]),
      legalEntity: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.getMiniCartDetails();
    this.handleSubscriptions();
  }

  getMiniCartDetails() {
    this.globalService.getCartsListByLegalEntity();
    let legalEntities = this.storageService.userLegalEntities ? this.storageService.userLegalEntities : [];
    this.legalEntityList = legalEntities ? legalEntities.map(item => item.name) : [];
    this.legalEntityListUid = legalEntities ? legalEntities.map(item => item.uid) : [];
  }

  handleSubscriptions() {
    // MiniCart Get API 
    this.cartsList$ = this.globalService.carts$.pipe(
      tap(data => this.createNewCart(data)),
      takeUntil(this.unsubscribe$)
    )

    // On Cart Change - Manage Cart Get API
    this.globalService.activeCart$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        if (data && !this.router.url.includes(AppConstants.routeUrls.manageCarts)) {
          this.globalService.getCartsListByLegalEntity(undefined, true);
        }
      });
  }

  createNewCart(data) {
    let cartsList = data?.carts ? data.carts : [];
    if (cartsList?.length === 0 && this.storageService.carts?.length === 0) {
      let legalEntityId = this.storageService.defaultLegalEntity?.uid;
      if (legalEntityId) {
        this.globalService.createNewCart(legalEntityId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            this.globalService.getCartsListByLegalEntity(undefined, true);
          })
      }
    }
  }

  toggle() {
    this.fromToggle = true;
    this.toggleCarts = !this.toggleCarts;
  }

  navigateToCart() {
    this.router.navigate([AppConstants.routeUrls.cart]);
  }

  changeCart(index) {
    this.toggleCarts = !this.toggleCarts;
    let cartsList = this.storageService.carts ? this.storageService.carts : [];
    if (cartsList?.length > 0) {
      this.globalService.loadingSubject.next(true);
      let cartObj = cartsList[index + 1];
      cartsList = cartsList.filter((v, i) => i !== index + 1);
      cartsList = [cartObj, ...cartsList];
      this.globalService.setActiveCart(cartsList, cartObj);
    }
  }

  newCartClick() {
    this.saveCartForm.get('cartName').reset();
    this.saveCartForm.get('legalEntity')?.setValue(this.storageService?.defaultLegalEntity?.name);
    this.modalInstance = new bootstrap.Modal(this.cartModal.nativeElement);
    this.modalInstance.show();
  }

  navigateToManageCarts() {
    this.router.navigate([AppConstants.routeUrls.manageCarts]);
    this.toggleCarts = !this.toggleCarts;
  }

  onNewCartClick() {
    if (this.saveCartForm.invalid) {
      for (const control of Object.keys(this.saveCartForm.controls)) {
        this.saveCartForm.controls[control].markAsTouched();
      }
      return;
    }
    const index = this.legalEntityList.indexOf(this.saveCartForm.value['legalEntity']);
    const paramsObject = {
      fields: "FULL",
      legalEntityId: this.legalEntityListUid[index],
      cartName: this.saveCartForm.value['cartName'],
    }
    this.globalService.loadingSubject.next(true);
    this.globalService.createCart(paramsObject)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (success) => {
          if (success && this.modalInstance) {
            this.modalInstance.hide();
            this.globalService.updateLegalEntity(index);
          }
        }, error: (error) => {
          this.globalService.loadingSubject.next(false);
          if (this.modalInstance) this.modalInstance.hide();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
    this.globalService.clearMessagesOnDestroy();
  }
}
