import { Component, Inject, HostListener, inject } from '@angular/core';
import { Router } from '@angular/router';
import { CmsParagraphComponent } from '@spartacus/core';
import { CmsComponentData, ParagraphComponent } from '@spartacus/storefront';
import { StorageService } from '../../services/storage.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '@spartacus/core';
import { UserProfileService } from '../../../SGRE-custom/user-profile/services/user-profile.service';

@Component({
  selector: 'app-shared-paragraph',
  templateUrl: './shared-paragraph.component.html',
  styleUrl: './shared-paragraph.component.scss'
})
export class SharedParagraphComponent extends ParagraphComponent {
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    let anchor: HTMLAnchorElement | null = null;
    if (target.tagName === 'A') {
      anchor = target as HTMLAnchorElement;
    } else if (target.closest('A')) {
      anchor = target.closest('A') as HTMLAnchorElement;
    }
    const url = anchor?.getAttribute('data-parent');
    if (url) {
      window.open(`${window.location.origin}${url}`, '_blank');
    }
  }
  public data$: Observable<CmsParagraphComponent>;
  public role: any = '';
  public userRole: any = ''
  public componentData: any;
  loginDisplay = new BehaviorSubject(false);
  user$: Observable<User | undefined>;
  private readonly userProfileService = inject(UserProfileService);

  constructor(
    private _component: CmsComponentData<CmsParagraphComponent>,
    private _router: Router,
    private storageService: StorageService
  ) {
    super(_component, _router);
  }

  ngOnInit(): void {
    this.data$ = this._component.data$;
    this.checkAccess();

    this.userProfileService?.userProfileSubjectData
      .subscribe((data) => {
        let paragraph: any = document.getElementsByTagName('app-privacy-policy-paragraph');
        if (paragraph) {
          if (data.email !== '' && data.isInternal) {
            paragraph[1].style.display = 'none';
          } else if (data.email !== '' && !data.isInternal) {
            paragraph[0].style.display = 'none';
          }
        }
      });
  }

  checkAccess() {
    // this.userRole= this.storageService.getItem('role')
    this.userRole = this.storageService.userRoles;
    if (this.userRole?.includes("Requester")) {
      this.role = 'Requester'
    }
    else if (this.userRole?.includes("Purchaser")) {
      this.role = 'Purchaser'
    }
    else if (this.userRole?.includes("Customer Admin")) {
      this.role = 'Customer Admin'
    }
    else if (this.userRole?.includes("SGRE Admin")) {
      this.role = 'SGRE Admin'
    }
    else if (this.userRole?.includes("Sales Rep")) {
      this.role = 'Sales Rep'
    }
  }
}