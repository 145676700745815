import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { LanguageService } from '@spartacus/core';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';
import { MyProfileService } from '../../../my-profile/services/my-profile.service';

@Component({
  selector: 'app-language-selector-header',
  templateUrl: './language-selector-header.component.html',
  styleUrl: './language-selector-header.component.scss'
})
export class LanguageSelectorHeaderComponent {

  selected: any = '';
  public currentLanguage: any = 'ENG';
  myHeaderForm: FormGroup;
  public isLoggedIn: boolean = false;

  constructor(
    private languageService: LanguageService,
    private fb: FormBuilder,
    private globalService: GlobalService,
    private myProfileService: MyProfileService
  ) { }

  ngOnInit() {
    this.globalService.loginBanner$.subscribe((data) => {
      this.isLoggedIn = data;
    })
    this.languageService.getActive().subscribe((lang) => {
      switch (lang) {
        case 'en':
          this.selected = 'ENG'
          break;
        case 'de':
          this.selected = 'GER'
          break;
        default:
          this.selected = 'ESP'
      }
      this.currentLanguage = lang;
      this.returnCurrentSelector(this.currentLanguage)
    });
    this.globalService.languageValue$.subscribe((x) => {
      if (x) {
        this.myHeaderForm.patchValue({
          language: x,
        });
      }
    })

    this.myHeaderInitialise();
  }

  returnCurrentSelector(value) {
    if (value == 'de') {
      return 'GER'
    }
    else if (value == 'es') {
      return 'ESP'
    }
    else {
      return 'ENG'
    }
  }

  selectLanguageCHandler(language: string) {
    this.languageService.setActive(language);
    if(!this.isLoggedIn){
      let langDateObj= {languageISOCode:language}
     this.languageDateChange(langDateObj);
    }
    window.location.reload();
  }

  languageDateChange(langDateObj){
    this.globalService.addLanguageDateChange(langDateObj).subscribe(()=>{
      // TO Apply currency and price formats to Table
      window.location.reload();
    })
  }

  myHeaderInitialise() {
    this.myHeaderForm = this.fb.group({
      language: [this.returnCurrentSelector(this.currentLanguage)],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.myHeaderForm.controls;
  }

}
