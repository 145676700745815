<ng-container *ngIf="showBanner$ | async">
  <p-messages severity="warn" styleClass="login-banner" [closable]="true">
    <ng-template pTemplate>
      <div class="ml-2 first">
        {{ "homePageHeader.youAreNotLogged" | cxTranslate }}
        <a class="primary-color-anchor signIn" (click)="login()">
          {{"homePageHeader.yourSign" | cxTranslate}}
        </a>
        {{ "homePageHeader.or" | cxTranslate }}
        <a class="primary-color-anchor" routerLink="{{ requestAccessUrl }}">
          {{ "homePageHeader.requestAccess" | cxTranslate }}
        </a>
        {{ "homePageHeader.yourGetExperience" | cxTranslate }}
      </div>
    </ng-template>
  </p-messages>
</ng-container>