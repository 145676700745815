import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { AuthGuard, ConfigModule, I18nModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';

import { CartComponent } from './Components/cart/cart.component';
import { CartProceedCheckoutComponent } from './Components/cart-proceed-checkout/cart-proceed-checkout.component';
import { CartCsvPdfComponent } from './Components/cart-csv-pdf/cart-csv-pdf.component';
import { RecreateModalPopupComponent } from './Components/recreate-modal-popup/recreate-modal-popup.component';


export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}

@NgModule({
  declarations: [
    CartComponent,
    CartProceedCheckoutComponent,
    CartCsvPdfComponent,
    RecreateModalPopupComponent
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    PrimengModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CartComponent: {
          component: () => import('./Components/cart/cart.component').then(m => m.CartComponent),
          guards: [CustomRouteGuard],
        },
        CSVAndPDFExportComponent: {
          component: () => import('./Components/cart-csv-pdf/cart-csv-pdf.component').then(m => m.CartCsvPdfComponent),
          guards: [CustomRouteGuard],
        },
        CartProceedToCheckoutComponent: {
          component: () => import('./Components/cart-proceed-checkout/cart-proceed-checkout.component').then(m => m.CartProceedCheckoutComponent),
          guards: [CustomRouteGuard],
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule
  ],
  providers: [
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class CartModule { }
