<!-- <div #testDiv></div> -->
<div id="demo" [innerHTML]="componentData.content | trust: 'html'"></div>

<!-- <div>
  <div class="mb-3">Siemens Gamesa Renewable Energy</div>
  <div class="footer-contact">
    <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" class="bi bi-geo-alt"
      viewBox="0 0 16 16">
      <path
        d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
    </svg>
    <div class="mb-1">
      <div>Parque Tecnológico de Bizkaia, Edificio 222</div>
      <div>48170, Zamudio, Vizcaya</div>
      <div>Spain</div>
    </div>
  </div>
  <div class="footer-contact">
    <svg class="mr-3" xmlns="http://www.w3.org/2000/svg" width="26" height="28" fill="currentColor" class="bi bi-telephone"
      viewBox="0 0 16 16">
      <path
        d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
    </svg>
    <div class="mb-4">
      <div>+ 34 944 03 73 52 (International)</div>
      <div>902 734 949 (Spain)</div>
    </div>
  </div>
  <div>
    <button type="button" class="btn btn-style cstm-btn-primary" data-toggle="button" aria-pressed="true"
      autocomplete="off">Contact us</button>
  </div>
</div> -->

<!-- <div class="custom-mb-4">
  <div class="mb-3">Siemens Gamesa Renewable Energy</div>
  <div class="footer-contact d-flex mb-3">
    <i class="fa-solid fa-location-dot footer-address-icon mr-3"></i>
    <div class="mb-1">
      <div>Parque Tecnológico de Bizkaia, Edificio 222</div>
      <div>48170, Zamudio, Vizcaya</div>
      <div>Spain</div>
    </div>
  </div>
  <div class="footer-contact d-flex">
    <i class="fa-solid fa-phone footer-address-icon mr-3"></i>
    <div>
      <div>+ 34 944 03 73 52 (International)</div>
      <div>902 734 949 (Spain)</div>
    </div>
  </div>
</div> -->

<!-- EXAMPLE - 1 -->
<!-- <div class="mb-4">
  <div class="mb-2">
    <input type="text" id="defaultInput" placeholder="enter something here" />
  </div>
  <div class="mb-2">
    <button type="button" id="Test1Btn" class="btn btn-style cstm-btn-primary" data-toggle="button" aria-pressed="true"
      autocomplete="off">Test1 method console</button>
  </div>
  <div class="mb-2">
    <button type="button" id="showDialogBtn" class="btn btn-style cstm-btn-primary" (click)="showDialog = true">Show
      Dialog</button>
  </div>
  <div class="mb-2">
    <input type="text" pInputText id="primengInput" [ngModel]="value" (ngModelChange)="captureValueChange()" />
  </div>
  <div class="mb-2">
    <p-dialog header="Edit Profile" [modal]="true" [visible]="showDialog" [style]="{ width: '25rem' }">
      <div>Dialog Works</div>
    </p-dialog>
  </div>
  <div class="card flex justify-content-center">
    <p-accordion [activeIndex]="0">
      <p-accordionTab header="Header I">
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
          est laborum.
        </p>
      </p-accordionTab>
      <p-accordionTab header="Header II">
        <p>
          Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem
          aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          Nemo enim
          ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
          ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
        </p>
      </p-accordionTab>
      <p-accordionTab header="Header III">
        <p>
          At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti
          atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique
          sunt in culpa
          qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et
          expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
        </p>
      </p-accordionTab>
    </p-accordion>
  </div>
</div> -->

<!-- Console Tab -> copied from console  -->
<!-- <div class="mb-4">
  <div> <input type="text" placeholder="enter something here" /> </div>
  <div> <button type="button" class="btn btn-style cstm-btn-primary" data-toggle="button" aria-pressed="true"
      autocomplete="off" (click)="Test1()">Test1 method console</button> </div>
  <div> <button type="button" class="btn btn-style cstm-btn-primary" (click)="showDialog = true">Show Dialog</button>
  </div>
  <div> <input type="text" pInputText #searchInput (change)="textChange(searchInput.value)" [(ngModel)]="value" />
  </div>
  <div> <p-dialog header="Edit Profile" [modal]="true" [visible]="showDialog" [style]="{ width: '25rem' }">
      <div>Dialog Works</div>
    </p-dialog> </div>
</div> -->

<!-- Elements Tab -->
<!-- <div _ngcontent-ng-c2988552956="" class="address-content">
  <div class="mb-4">
    <div> <input type="text" placeholder="enter something here"> </div>
    <div> <button type="button" class="btn btn-style cstm-btn-primary" data-toggle="button" aria-pressed="true"
        autocomplete="off" (click)="Test1()">Test1 method console</button> </div>
    <div> <button type="button" class="btn btn-style cstm-btn-primary" (click)="showDialog = true">Show Dialog</button>
    </div>
    <div> <input type="text" pinputtext="" #searchinput="" (change)="textChange(searchInput.value)" [(ngmodel)]="value">
    </div>
    <div> <p-dialog header="Edit Profile" [modal]="true" [visible]="showDialog" [style]="{ width: '25rem' }">
        <div>Dialog Works</div>
      </p-dialog> </div>
  </div>
</div> -->

<!-- copied content -->
<!-- <div _ngcontent-ng-c994081288="" class="mb-4">
  <div _ngcontent-ng-c994081288=""><input _ngcontent-ng-c994081288="" type="text" placeholder="enter something here">
  </div>
  <div _ngcontent-ng-c994081288=""><button _ngcontent-ng-c994081288="" type="button" data-toggle="button"
      aria-pressed="true" autocomplete="off" class="btn btn-style cstm-btn-primary">Test1 method console</button></div>
  <div _ngcontent-ng-c994081288=""><button _ngcontent-ng-c994081288="" type="button"
      class="btn btn-style cstm-btn-primary">Show Dialog</button></div>
  <div _ngcontent-ng-c994081288=""><input _ngcontent-ng-c994081288="" type="text" pinputtext=""
      class="p-inputtext p-component p-element ng-untouched ng-pristine ng-valid p-filled"
      ng-reflect-model="Primeng Component Works!!"></div>
  <div _ngcontent-ng-c994081288=""><p-dialog _ngcontent-ng-c994081288="" header="Edit Profile"
      class="p-element ng-tns-c662934324-1" ng-reflect-header="Edit Profile" ng-reflect-modal="true"
      ng-reflect-visible="false"></p-dialog></div>
</div> -->