import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, concatMap, filter, forkJoin, from, mergeMap, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../SGRE-shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class HomepageService {

  private imageList = new BehaviorSubject<any[]>([]);
  data$ = this.imageList.asObservable();

  private categoriesListSubject = new BehaviorSubject<any>(null);
  dataCat$ = this.categoriesListSubject.asObservable();

  constructor(
    private http: HttpClient,
    private apiService: ApiService
  ) {
  }

  public getCategoriesAPI() {
    let paramsObject = { fields: 'FULL' }

    this.apiService.getHomePageCategories(paramsObject)
      .subscribe({
        next: (data: any) => {
          const filteredData = data.categories.filter(item => item.code != 'TOP FEATURED PRODUCTS');
          this.categoriesListSubject.next(filteredData);
        }
      });
  }

  getCarouselImages(banners: string[]) {
    of(banners)
      .pipe(
        mergeMap((banner: string[]) => {
          let fields = 'DEFAULT';
          let currentPage = 0;
          let pageSize = banners.length;

          return forkJoin(
            banner.map((b) => {
              return this.apiService.getCarouselImagesHome(fields, currentPage, pageSize, b)
            })
          );
        })
      )
      .subscribe((data) => this.imageList.next(data));
  }
}
