import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';

import { AccessRequestTableComponent } from './components/access-request-table/access-request-table.component'

@NgModule({
  declarations: [
    AccessRequestTableComponent
  ],
  imports: [
    CommonModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccessRequestTableComponent: {
          component: () => import('./components/access-request-table/access-request-table.component').then(m => m.AccessRequestTableComponent),
          guards: [CustomRouteGuard]
        }
      }
    })
  ]
})
export class AccessRequestModule { }
