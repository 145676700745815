<div data-toggle="modal" class="parent-text" data-target="#exampleModalLong">
  <a class="cstm-anchor-primary">
    Click here to see the video
    <strong> <img alt="" src="../../../../../assets/images_and_icons/right-arrow.svg"></strong>
  </a>
</div>
<div class="modal fade"  tabindex="-1" id="exampleModalLong" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" data-target="#closeVideo" (click)="stopVideo(elRef)" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" id="closeVideo" >
        <video #elRef style="width: 100%; height: 100%;" controls controlsList="nodownload">
        <source src="https://backoffice.c4khy-siemensga1-p1-public.model-t.cc.commerce.ondemand.com/medias/210211-SGRCOM-Shop-MM-SoMe-LI-FB-Post-05.mp4?context=bWFzdGVyfHJvb3R8MzU0MTkxNjB8dmlkZW8vbXA0fGFHTm1MMmc0TVM4NU9UWTNNekl5T0RVM05UQXlMekl4TURJeE1TMVRSMUpEVDAwdFUyaHZjQzFOVFMxVGIwMWxMVXhKTFVaQ0xWQnZjM1F0TURVdWJYQTB8YWQyZTFiYzAxYzYxMjJkNDAzOWFhZTdjZWQ3ZWMyNzZkYzQ3MjNlMjA0MjMwMWI2OTQyYTg5MjFlZTlhMTI2Mg" type="video/mp4"></video>

    </div>
    </div>
  </div>
</div>
