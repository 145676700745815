import { Component, OnInit } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-not-found-paragraph',
  templateUrl: './page-not-found-paragraph.component.html',
  styleUrl: './page-not-found-paragraph.component.scss'
})
export class PageNotFoundParagraphComponent implements OnInit {

  public data$: Observable<any>;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.data$ = this.component.data$;
  }
}
