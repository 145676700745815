import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BulkOrderService } from '../../../../SGRE-custom/bulk-order-page/services/bulk-order-service';
import { StorageService } from '../../../services/storage.service';
import { CmsComponentData } from '@spartacus/storefront';
import { CmsParagraphComponent } from '@spartacus/core';
@Component({
  selector: 'app-bulkorder-product-found-paragraph',
  templateUrl: './bulkorder-product-found-paragraph.component.html',
  styleUrl: './bulkorder-product-found-paragraph.component.scss'
})
export class BulkorderProductFoundParagraphComponent {
  currentpage$: Observable<number>
  public userRoles: any[];
  @Input() data: Observable<CmsParagraphComponent>;
  role: string;
  productLength: number = 0
  public componentData: any;
  constructor(public bulkservice: BulkOrderService,public component: CmsComponentData<any>, public changeRef: ChangeDetectorRef, public storageService: StorageService) { }
  ngOnInit(): void {
    this.currentpage$ = this.bulkservice.isNext$;
    this.getUserRole();
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
  getUserRole() {
    let userRole: any = ''
    userRole = this.storageService.userRoles;

    if (userRole?.includes("Requester") && !userRole?.includes("Purchaser")) {
      this.role = 'Requester';
    }
    else if (userRole?.includes("Purchaser") && !userRole?.includes("Requester")) {
      this.role = 'Purchaser';
    }
    else if (userRole?.includes("Purchaser") && userRole?.includes("Requester")) {
      this.role = 'Purchaser&Requester'
      //this.role='Purchaser&Requester'
    }


  }
  getData() {
    this.bulkservice.tableDataSubject.subscribe((req) => {
      const data = req
      console.log('req, in found table', data)
      if (Object.keys(data).length > 0) {
        this.productLength = data?.foundProductCodes?.length
        // console.log("DATA",req.foundProductCodes)
      }
    })
    this.changeRef.detectChanges();
  }
}
