import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { FeaturesConfig, I18nConfig, OccConfig, provideConfig, SiteContextConfig } from "@spartacus/core";
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import { environment } from '../../environments/environment';


@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig(<OccConfig>{
      backend: {
        occ: {
          baseUrl: environment.siteUrls.getBaseUrl,
          prefix: environment.siteUrls.getPrefix,
        }
      },
    }),
    provideConfig({
      features: {
        userCmsEndpoints: true
      },
    }),
    provideConfig(<SiteContextConfig>{
      context: {
        language: ['en', 'de', 'es']
      },
    }),
    provideConfig(<I18nConfig>{
      i18n: {
        backend: {
          loadPath: 'assets/i18n/{{lng}}.json',
        },
        resources: translations,
        chunks: translationChunksConfig,
        fallbackLang: 'en'
      },
    }),
    provideConfig(<FeaturesConfig>{
      features: {
        level: '2211.27'
      }
    }),
  ]
})
export class SpartacusConfigurationModule { }
