import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../../SGRE-shared/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private apiService: ApiService) { }

  public getLinksData(param: any): Observable<any> {
    let fields = 'FULL';
    let pageType = 'ContentPage';

    return this.apiService.getCmsComponents(fields, pageType, param);
  }
}