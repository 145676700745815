<ng-container *ngIf="(isLoggedIn$ | async)">
   <div *ngIf="showPoster" (click)="playVideo()" class="thumbnail">
      <img [src]="thumbnailUrl" alt="no thumbnail" class="video-poster">
      <button class="play-button">&#9658;</button>
   </div>
   <video *ngIf="!showPoster" #videoPlayer (ended)="onVideoEnd()" style="width: 100%; height: 100%;" controls
      controlsList="nodownload">
      <source [src]="videoUrl" type="video/mp4">
      Your browser does not support HTML video.
   </video>
</ng-container>