import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { AuthGuard, ConfigModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';

import { FooterContainerComponent } from './Components/footer-container/footer-container.component';
import { FooterNavigationComponent } from './Components/footer-navigation/footer-navigation.component';

@NgModule({
  declarations: [
    FooterContainerComponent,
    FooterNavigationComponent,
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    PrimengModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        FooterContainerComponent: {
          component: FooterContainerComponent,
        },
        FooterNavigationComponent: {
          component: FooterNavigationComponent,
        }
      }
    })
  ]
})
export class FooterModule { }
