import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';


@Component({
  selector: 'app-my-profile-paragraph',
  templateUrl: './my-profile-paragraph.component.html',
  styleUrl: './my-profile-paragraph.component.scss'
})
export class MyProfileParagraphComponent {
  public componentData: any;

  

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
   
  }

 

}
