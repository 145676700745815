import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-footer-follow-us-text',
  templateUrl: './footer-follow-us-text.component.html',
  styleUrl: './footer-follow-us-text.component.scss'
})
export class FooterFollowUsTextComponent {
  public componentData: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
}
