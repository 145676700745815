<div class="d-flex">
  <div class="link-cls pr-3">
    <p class="m-0">
      <a (click)="login()" class="m-0"
        >{{"homePageHeader.signIn" |cxTranslate}}<strong>
          <img
            alt=""
            src="../../../../../assets/images_and_icons/right-arrow.svg" /></strong
      ></a>
    </p>
  </div>
  <div class="link-cls">
    <p class="m-0">
      <a href="/request-access" class="m-0"
        >{{"homePageHeader.requestAccess" |cxTranslate}}<strong>
          <img
            alt=""
            src="../../../../../assets/images_and_icons/right-arrow.svg" /></strong
      ></a>
    </p>
  </div>
</div>
