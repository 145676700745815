
<div class="icon-cont">
  <a class="social-icon" target="_blank" href="{{componentData.url}}">
    <i class="fa-brands fa-x-twitter"></i>
  </a>
</div>
<!-- <div class="sg-social-footer-icons main-icon mb-4">
    <div class="footer-link-header">Follow us on</div>
    </div>
     <div class="d-inline-flex mb-3">
        <div class="icon-cont custom-flex-center">
          <div class="icon-cont">
            <a class="social-icon" target href="{{componentData.url}}">
              <i class="fa-brands fa-x-twitter"></i>
            </a>
            </div>
        <div class="icon-cont">
            <a class="social-icon" href="{{componentData.url}}" role="button">
                <i class="fab fa-facebook-f"></i>
            </a>
        </div>
        <div class="icon-cont">
            <a class="social-icon" href="{{componentData.url}}" role="button">
                <i class="fab fa-linkedin-in"></i>
            </a>
        </div>
        <div class="icon-cont">
            <a class="social-icon" href="{{componentData.url}}" role="button">
                <i class="fab fa-instagram"></i>
            </a>
        </div>
    </div>
</div> -->
