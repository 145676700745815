<div *ngIf="!(userLoggedIn | async)">
  <div *ngIf="legalEntityListNew | async as legalEntities; else readFromSession"
    class="dropdown legal-entity-container">
    <ng-container *ngIf="legalEntities?.length > 0; else loading">
      <div *ngIf="selectedLegalEntity | async as selected">
        <a *ngIf="selected" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          class="nav-link dropdown-toggle secondary-anchor">
          {{(selected?.name)?selected.name:'Loading...'}}</a>
      </div>
      <div class="dropdown-menu mt-0 legal-entity-options">
        <input type="text" pInputText placeholder="{{'searchLegalEntity' | cxTranslate}}" class="dropdown-item-search"
          [(ngModel)]="searchText" />
        <a *ngFor="let legalEntity of legalEntities  | filterDropdown: searchText" class="dropdown-item"
          (click)="onValueChange(legalEntity)">
          {{legalEntity}}
        </a>
      </div>
    </ng-container>
  </div>
  <ng-template #readFromSession>
    <div *ngIf="legalEntityListSession?.length>0 && selectedLegalEntitySession?.name; else loading"
      class="dropdown legal-entity-container">
      <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
        *ngIf="selectedLegalEntity | async as selected; else sessionLegalEntity"
        class="nav-link dropdown-toggle secondary-anchor">{{ selected.name }}</a>
      <ng-template #sessionLegalEntity>
        <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
          class="nav-link dropdown-toggle secondary-anchor">
          {{selectedLegalEntitySession.name}}
        </a>
      </ng-template>
      <div class="dropdown-menu legalEntities-header mt-0 legal-entity-options">
        <input type="text" pInputText placeholder="{{'searchLegalEntity' | cxTranslate}}" class="dropdown-item-search"
          [(ngModel)]="searchText" />
        <a *ngFor="let legalEntity of legalEntityListSession | filterDropdown: searchText" class="dropdown-item"
          (click)="onValueChange(legalEntity)">
          {{legalEntity}}</a>
      </div>
    </div>
  </ng-template>
  <ng-template #loading>
    <div class="loading-txt">Loading...</div>
  </ng-template>
</div>