import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-image',
  templateUrl: './footer-image.component.html',
  styleUrl: './footer-image.component.scss'
})
export class FooterImageComponent {

}
