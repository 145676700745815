<app-footer-socialmedia-icons *ngIf="componentData?.uid === 'XLink'"></app-footer-socialmedia-icons>

<app-footer-social-media-facebook *ngIf="componentData?.uid === 'FaceBookLink'"></app-footer-social-media-facebook>

<app-footer-social-media-instagram *ngIf="componentData?.uid === 'InstagramLink'"></app-footer-social-media-instagram>

<app-footer-social-media-linkedin *ngIf="componentData?.uid === 'LinkedInLink'"></app-footer-social-media-linkedin>

<app-header-socialmedia-icons *ngIf="componentData?.uid === 'XHeaderLink'"></app-header-socialmedia-icons>

<app-social-media-link-header-facebook *ngIf="componentData?.uid === 'FaceBookHeaderLink'">
</app-social-media-link-header-facebook>

<app-social-media-link-header-instagram *ngIf="componentData?.uid === 'LinkedInHeaderLink'">
</app-social-media-link-header-instagram>

<app-social-media-link-header-linkedin *ngIf="componentData?.uid === 'InstagramHeaderLink'">
</app-social-media-link-header-linkedin>

<app-header-contactus *ngIf="componentData?.uid === 'ContactUsLink'"></app-header-contactus>

<app-header-faq *ngIf="componentData?.uid === 'FAQHeaderLink'"></app-header-faq>

<app-footer-contact-us *ngIf="componentData?.uid === 'FooterContactUsLink'"></app-footer-contact-us>

<app-cookie-policy-social *ngIf="componentData?.uid === 'FaceBookCookiePolicyLink'"></app-cookie-policy-social>

<app-terms-of-use-social-media *ngIf="componentData?.uid === 'FaceBooktermsofuseLink'">
</app-terms-of-use-social-media>

<app-privacy-policy-social-media *ngIf="componentData?.uid === 'FaceBookPrivacyPolicyLink'">
</app-privacy-policy-social-media>

<app-privacy-policy-section3-buttons *ngIf="componentData?.uid === 'PrivacyPolicyNoticeENLink' ||
 componentData?.uid === 'PrivacyPolicyNoticeDELink' || componentData?.uid === 'PrivacyPolicyNoticeESLink' ||
 componentData?.uid === 'PrivacyPolicyNoticeBRLink' ||  componentData?.uid === 'DataSubjectRequestLink'">
</app-privacy-policy-section3-buttons>

<app-page-not-found-link *ngIf="componentData?.uid==='HomepageNavLink' || componentData?.uid==='ProductPageNavLink'">
</app-page-not-found-link>