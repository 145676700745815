import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstants } from '../../../SGRE-shared/constants/app-constant';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../SGRE-shared/services/api.service';
import { GlobalService } from '../../../SGRE-shared/services/global.service';
import { StorageService } from '../../../SGRE-shared/services/storage.service';
@Injectable({
  providedIn: 'root'
})
export class FeaturedProductsService {

  private featuredProductListSubject = new BehaviorSubject<any>(null);
  dataFeaturedProduct$ = this.featuredProductListSubject.asObservable();
  userId: string;
  public legalEntityListSession: string[];
  public selectedLegalEntitySession: any;

  constructor(private readonly http: HttpClient, private apiService: ApiService, private globalService: GlobalService, private storageService: StorageService) {
    this.getFeaturedProductList();
  }

  public getFeaturedProductList() {
    this.userId = this.globalService.getUserId();
    this.legalEntityListSession = this.storageService.userLegalEntities
    ? this.storageService.userLegalEntities?.map((item) => item?.name)
    : [];
    this.selectedLegalEntitySession = this.storageService.defaultLegalEntity
    ? this.storageService.defaultLegalEntity
    : '';
    if(this.selectedLegalEntitySession.name || this.userId == 'anonymous')
    {
      this.apiService.getTopFeaturedProductList(0, 'FULL', 20).subscribe({
        next: (data) => this.featuredProductListSubject.next(data),
        error: (error) => console.log("Error from Featured Product Service")
      });
    }
    else
    {
      this.featuredProductListSubject.next({products: []})
    }
  }
}
