<div *ngIf="modalpopup">
  <div class="modal fade" tabindex="-1" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">{{"orderDetail.confirmation"|cxTranslate}}</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{"orderDetail.messageSent"|cxTranslate}}</p>
        </div>
      </div>
    </div>
  </div>
</div>