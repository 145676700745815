import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { HomepageService } from '../../services/homepage.service';
import { Observable, Subscription, interval, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CmsComponentData } from '@spartacus/storefront';
import { GlobalService } from '../../../../SGRE-shared/services/global.service';

@Component({
  selector: 'app-homepage-carousel-section1',
  templateUrl: './homepage-carousel-section1.component.html',
  styleUrl: './homepage-carousel-section1.component.scss',
})

export class HomepageCarouselSection1Component implements OnInit, OnDestroy {

  i: any = 0;
  current = 0;
  lengthArray: any;
  array: any = [];
  listImg: any = [];
  subscription: Subscription;

  constructor(
    public Homepageservice: HomepageService,
    private changeRef: ChangeDetectorRef,
    private globalService: GlobalService,
    public component: CmsComponentData<any>
  ) { }

  ngOnInit() {
    this.component.data$
      .pipe(
        map(data => {
          let arr: string[] = (data?.banners?.length > 0)
            ? data.banners.split(' ')
            : [];
          return arr;
        })
      )
      .subscribe(data => this.Homepageservice.getCarouselImages(data));

    this.Homepageservice.data$.subscribe((data: any) => {
      if (data?.length > 0) {
        this.array = [];
        for (const item of data) {
          this.array.push(environment.siteUrls.getBackofficeUrl + item.component?.at(0)?.media?.url);
        }
        this.lengthArray = this.array.length;
        this.setData(this.array);
        this.changeRef.detectChanges();
      }
    });

    this.subscription = interval(10000).subscribe(() => {
      this.i = ++this.current % this.listImg.length;
      this.changeRef.markForCheck();
    });
  }

  setData(a: any) {
    this.listImg = a;
    this.lengthArray = this.listImg.length;
  }

  prevSlide() {
    this.i = (this.i > 0) ? --this.i : this.lengthArray - 1;
  }

  nextSlide() {
    this.i = (this.i < this.lengthArray - 1) ? ++this.i : 0;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.globalService.clearMessagesOnDestroy();
  }
}
