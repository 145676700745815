import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-privacy-policy-section3-buttons',
  templateUrl: './privacy-policy-section3-buttons.component.html',
  styleUrl: './privacy-policy-section3-buttons.component.scss'
})
export class PrivacyPolicySection3ButtonsComponent {
  public componentData: any;


  constructor(public component: CmsComponentData<any>) { }


  ngOnInit(): void{
    this.component.data$.subscribe((data) => {
          this.componentData = data;
        });
      }
}
