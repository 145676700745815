import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { SGRESharedModule } from '../../../SGRE-shared/SGRE-shared.module';
import { CookiePolicyContainerComponent } from '../Components/cookie-policy-container/cookie-policy-container.component';



@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSTabParagraphComponent:{
          component: () => import('../Components/cookie-policy-container/cookie-policy-container.component').then(m => m.CookiePolicyContainerComponent),
        },
      }
    })
  ]
})
export class CookiePolicyModule { }
