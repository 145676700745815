import { Component } from '@angular/core';
import { CmsComponentData } from '@spartacus/storefront';

@Component({
  selector: 'app-purchase-terms-and-condition',
  templateUrl: './purchase-terms-and-condition.component.html',
  styleUrl: './purchase-terms-and-condition.component.scss'
})
export class PurchaseTermsAndConditionComponent {
  public componentData: any;

  constructor(public component: CmsComponentData<any>) { }

  ngOnInit(): void {
    this.component.data$.subscribe((data) => {
      this.componentData = data;
    });
  }
}
