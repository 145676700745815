import { provideHttpClient, withFetch, withInterceptorsFromDi } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { AppRoutingModule } from "@spartacus/storefront";
import { AppComponent } from './app.component';
import { SpartacusModule } from "./spartacus/spartacus.module";
import { SGRECustomModules } from "./SGRE-custom/SGRE-custom.module";
import { SGRESharedModule } from "./SGRE-shared/SGRE-shared.module";

// Import MSAL and MSAL browser libraries
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';

// Import the Azure AD related Services
import { AuthHttpHeaderService, AuthService } from "@spartacus/core";
import { MSALAuthHttpHeaderService } from "./SGRE-shared/services/login-sso/msal-auth-http-header.service";
import { MSALSpaAuthService } from "./SGRE-shared/services/login-sso/msal-auth.service";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    EffectsModule.forRoot([]),
    SpartacusModule,
    SGRECustomModules,
    SGRESharedModule.forRoot(),
    MsalModule.forRoot(
      MSALSpaAuthService.msalInstance,
      null,
      null
    )
  ],
  providers: [
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    provideAnimationsAsync(),
    {
      provide: AuthHttpHeaderService,
      useExisting: MSALAuthHttpHeaderService,
    },
    {
      provide: AuthService,
      useExisting: MSALSpaAuthService,
    },
  ],
  bootstrap: [
    AppComponent,
    MsalRedirectComponent
  ]
})
export class AppModule { }
