import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import { ConfigModule, I18nModule } from '@spartacus/core';
import { PageSlotModule } from '@spartacus/storefront';
import { SharedCheckoutService } from './services/sharedcheckout.service';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';

import { PricedEntriesAccordionComponent } from './Components/priced-entries-accordion/priced-entries-accordion.component';
import { RequestAndDuplicateCartButtonComponent } from './Components/request-and-duplicate-cart-button/request-and-duplicate-cart-button.component';
import { PrimengModule } from '../primeng/primeng.module';
import { CheckoutDetailsComponent } from './Components/checkout-detail/checkout-details.component';


export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}

@NgModule({
  declarations: [
    PricedEntriesAccordionComponent,
    RequestAndDuplicateCartButtonComponent,
    CheckoutDetailsComponent
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    SGRESharedModule,
    PrimengModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutDetailsComponent: {
          component: () => import('./Components/checkout-detail/checkout-details.component').then(m => m.CheckoutDetailsComponent),
          guards: [CustomRouteGuard]
        },
        PricedEntriesAccordionComponent: {
          component: () => import('./Components/priced-entries-accordion/priced-entries-accordion.component').then(m => m.PricedEntriesAccordionComponent),
          guards: [CustomRouteGuard]
        },
        RequestAndDuplicateCartButtonComponent: {
          component: () => import('./Components/request-and-duplicate-cart-button/request-and-duplicate-cart-button.component').then(m => m.RequestAndDuplicateCartButtonComponent),
          guards: [CustomRouteGuard]
        },
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule
  ],
  providers: [
    TranslateService,
    SharedCheckoutService,
    { provide: TranslateStore }
  ]

})
export class CheckoutPageModule { }
