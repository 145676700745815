<div style="padding-left: 15px; padding-right: 15px;" class="component col-12 indent-top">
   <div class="component-content">
      <h3 style="color:#321850; text-align: center;">
        {{"homePageHeader.greatFeaturesGlance" |cxTranslate}}
      </h3>
   </div>
</div>
<div class="component image file-type-icon-media-link col-12">
   <div class="component-content">
      <a href="#"><img src="{{imageUrl}}" alt="Product page" width="100%" height="100%"
             data-variantfieldname="Image"></a><span
         class="image-caption field-imagecaption"></span>
   </div>
</div>
