
      <div class="icon-cont custom-flex-center">
          <a class="social-icon" target="_blank" href="{{componentData.url}}" role="button">
              <i class="sg-icon sg-icon-facebook"></i>
          </a>
          <!-- <ng-container [ngSwitch]="componentData.url">
  <ng-template *ngSwitchCase="componentData.name === 'X'">
    <i class="fa-brands fa-x-twitter"></i>
  </ng-template>

  <ng-template *ngSwitchCase="'Instagram'">
    <i class="fab fa-instagram"></i>
  </ng-template>

  <ng-template *ngSwitchCase="'FaceBook'">
    <i class="fab fa-facebook-f"></i>
  </ng-template>

  <ng-template *ngSwitchCase="'LinkedIn'">
    <i class="fab fa-linkedin-in"></i>
  </ng-template>
          </ng-container> -->
      </div>
