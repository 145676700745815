<div class="container-fluid">
  <div class="row footer-container">
    <div class="col-7 pr-0 footer-image-container">
      <div class="h-100">
        <cx-page-slot position="FooterImage"></cx-page-slot>
      </div>
    </div>
    <div class="col-5 pl-0 footer-contactus-container">
      <div class="footer-column-padding">
        <cx-page-slot position="FooterFollowUs"></cx-page-slot>
        <cx-page-slot position="FooterSocialMediaLinks"></cx-page-slot>
        <cx-page-slot position="FooterAddressAndContactInfo"></cx-page-slot>
        <cx-page-slot position="FooterContactUs"></cx-page-slot>
      </div>
    </div>
  </div>
  <div class="row align-items-center px-3 pt-4 pb-2 footer-nav-container">
    <div class="col-7 p-0">
      <cx-page-slot position="FooterNavigation"></cx-page-slot>
    </div>
  </div>
  <div class="col-12 copyright-container">
    <cx-page-slot position="FooterNoticeText"></cx-page-slot>
  </div>
</div>
