import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageSlotModule } from '@spartacus/storefront';
import { AuthGuard, ConfigModule, I18nModule } from '@spartacus/core';
import { PrimengModule } from '../primeng/primeng.module';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SGRESharedModule } from '../../SGRE-shared/SGRE-shared.module';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
  TranslateStore,
} from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';

import { CustomRouteGuard } from '../../SGRE-shared/services/guard/msal.guard';

import { OrderDetailtableComponent } from './components/order-detailtable/order-detailtable.component';
import { CsvPDFbuttonsComponent } from './components/csv-pdfbuttons/csv-pdfbuttons.component';
import { OrderInformationComponent } from './components/order-information/order-information.component';
import { OrderTotalSummaryComponent } from './components/order-total-summary/order-total-summary.component';
import { DeliveryInformationComponent } from './components/delivery-information/delivery-information.component';
import { OrderDetailsModalpopupComponent } from './components/order-details-modalpopup/order-details-modalpopup.component';
import { RecreatecancelComponent } from './components/recreate-cancel/recreate-cancel.component';


export function HttpLoaderFactory(http: HttpBackend) {
  return new MultiTranslateHttpLoader(http, ['/assets/i18n/core/', '/assets/i18n/vendors/']);
}

@NgModule({
  declarations: [
    OrderDetailtableComponent,
    RecreatecancelComponent,
    CsvPDFbuttonsComponent,
    OrderInformationComponent,
    OrderTotalSummaryComponent,
    DeliveryInformationComponent,
    OrderDetailsModalpopupComponent,
    // RecaptchaComponent
  ],
  imports: [
    CommonModule,
    PageSlotModule,
    PrimengModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SGRESharedModule,
    ConfigModule.withConfig({
      cmsComponents: {
        OrderInformationComponent: {
          component: () => import('./components/order-information/order-information.component').then(m => m.OrderInformationComponent),
          guards: [CustomRouteGuard]
        },
        DeliveryInformationComponent: {
          component: () => import('./components/delivery-information/delivery-information.component').then(m => m.DeliveryInformationComponent),
          guards: [CustomRouteGuard]
        },
        RecreateAndCancelButtonComponent: {
          component: () => import('./components/recreate-cancel/recreate-cancel.component').then(m => m.RecreatecancelComponent),
          guards: [CustomRouteGuard]
        },
        OrderDetailsTableComponent: {
          component: () => import('./components/order-detailtable/order-detailtable.component').then(m => m.OrderDetailtableComponent),
          guards: [CustomRouteGuard]
        },
        DownloadCSVandPDFComponent: {
          component: () => import('./components/csv-pdfbuttons/csv-pdfbuttons.component').then(m => m.CsvPDFbuttonsComponent),
          guards: [CustomRouteGuard]
        },
        OrderTotalSummaryComponent: {
          component: () => import('./components/order-total-summary/order-total-summary.component').then(m => m.OrderTotalSummaryComponent),
          guards: [CustomRouteGuard]
        }
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    }),
    I18nModule
  ],
  providers: [
    TranslateService,
    { provide: TranslateStore }
  ]
})
export class OrderDetailModule { }
