<div class="d-flex footerLink">
  <div *ngFor="let data of (data$ | async)?.children; index as i">
    <div class="mr-3" [ngClass]="i === 0 ? 'pr-3' : 'px-3'">
      <a *ngIf="(isLoggedIn$ | async) || data.title !== 'Purchase Terms & Conditions'" href="{{data.url}}"
        target="_blank" class="cstm-anchor-primary">
        {{data?.title}}
      </a>
    </div>
  </div>
</div>
