<div class="dropdown lang-selector-container">
	<a data-toggle="dropdown"
		aria-haspopup="true"
		aria-expanded="false"
		class="nav-link dropdown-toggle secondary-anchor">
		{{ selected }}
	</a>
	<div class="dropdown-menu mt-0 lang-selector-options">
		<a *ngIf="selected != 'ENG'" (click)="selectLanguageCHandler('en')"
			class="dropdown-item">
			{{"header.eng" |cxTranslate}}
		</a>
		<a *ngIf="selected != 'GER'" (click)="selectLanguageCHandler('de')"
			class="dropdown-item">
			{{"header.ger" |cxTranslate}}
		</a>
		<a *ngIf="selected != 'ESP'" (click)="selectLanguageCHandler('es')"
			class="dropdown-item">
			{{"header.esp" |cxTranslate}}
		</a>
	</div>
</div>
