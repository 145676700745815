import { Component } from '@angular/core';
import { AppConstants } from '../../../../SGRE-shared/constants/app-constant';
import { Observable, filter, from, map } from 'rxjs';
import { Router, RouterModule } from '@angular/router';
import { ProductListService } from '../../../product-list-page/services/product-list.service';
import { environment } from '../../../../../environments/environment';
import { StorageService } from '../../../../SGRE-shared/services/storage.service';
import { HomepageService } from '../../services/homepage.service';

@Component({
  selector: 'app-homepage-category-section3',
  templateUrl: './homepage-category-section3.component.html',
  styleUrl: './homepage-category-section3.component.scss'
})
export class HomepageCategorySection3Component {

  public categoryList$: Observable<any>;
  public imageUrl: any = environment.siteUrls.getBackofficeUrl.slice(0, -1);
  plpUrl = AppConstants.routeUrls.plp;
  categoriesName: string = ':name-asc:allCategories:';

  constructor(
    private homepageService: HomepageService,
    private productsService: ProductListService, private router: Router, private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.getCategoriesList();
  }

  public getCategoriesList() {
    this.homepageService.getCategoriesAPI();
    this.categoryList$ = this.homepageService.dataCat$;
  }

  getImageUrl(imgArr: any[]) {
    return imgArr && imgArr.length > 0 && environment.siteUrls.getBackofficeUrl + imgArr[0].url;
  }

  categoryClick(categoryName?: string) {
    if (categoryName?.length > 0) {
      let str = this.categoriesName + `"${categoryName}"`;
      this.storageService.query = str;
    } else {
      this.storageService.query = '';
      localStorage.removeItem(AppConstants.SessionStorageKeys.query);
    }
    this.router.navigate([AppConstants.routeUrls.plp]);
  }
}
